<template>
  <div>
    <h1 class="ms-form-title">{{ $t('auth.oidcLogin.errorPage.title') }}</h1>
    <div class="ms-information-block ms-media-column">
      {{ $t('auth.oidcLogin.errorPage.somethingWentWrong') }} <a :href="localizeUrl('/')">{{ $t('auth.oidcLogin.errorPage.tryAgain') }}</a>
    </div>
  </div>
</template>

<script setup>
import UserDataUtils from '@/util/UserDataUtils'
import UrlManager from '@/util/UrlManager'
import { onMounted } from 'vue'

//methods
function localizeUrl (path) {
  return UrlManager.localizeUrl(process.env.VUE_APP_SOLUTION_HOST, path)
}

//lifecycle
onMounted(() => {
  UserDataUtils.deleteAllUserData()
})
</script>

<style lang="scss" scoped>
</style>
