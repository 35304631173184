<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner-element :loading="isLoading"/>
      <div class="ms-otp-form-title">{{ $t('auth.otp.singInOtpPage.title') }}</div>
      <div class="ms-otp-caption" v-html="$t('auth.otp.singInOtpPage.description', [email])"></div>
      <div class="ms-otp-caption-bold">{{ $t('auth.otp.singInOtpPage.description.bold') }}</div>
      <form
        @submit.prevent="submitOtp"
        id="otpStepForm"
        autocomplete="off">
        <div class="ms-otp-form-row">
          <otp-input
            ref="entryOtpInputEl"
            :channel="otpChannel"
            :input-classes="'ms-otp-input ms-otp-input-code' + (otpFieldErrorMessage ? ' ms-otp-error-field' : '')"
            @on-change="handleOtpChange"
            @on-complete="handleOtpComplete"
          />
          <div class="ms-otp-error-field">
            <span
              v-if="otpFieldErrorMessage"
              class="ms-otp-error-text">{{ otpFieldErrorMessage }}</span>
          </div>
        </div>
        <div class="ms-otp-btn-wrap">
          <router-link
            :to="{ path: localizeUrl('/') }"
            class="ms-otp-btn ms-otp-btn-outline">
            {{ $t('default.back.title') }}
          </router-link>
          <input
            type="submit"
            class="ms-otp-btn"
            id="submit"
            :value="$t('default.next.title')"/>
        </div>
        <div class="ms-link-navigation ms-link-navigation-custom">
          <a
            class="ms-forward-link"
            href="javascript:"
            @click="sendOtpCode"
            v-html="resendCodeText"/>
        </div>
      </form>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-otp-code.webp"
        alt="login image"
      >
    </div>
  </div>
</template>

<script setup>
import { useValidationHandler } from '@/composables/useValidationHandler'
import { computed, getCurrentInstance, onMounted, ref, unref, watch } from 'vue'
import { OtpChannel } from '@/constants/OtpChannel'
import { useI18n } from 'vue-i18n'
import { SigninProcessService } from '@/service/SigninProcessService'
import { SigninProcessStep } from '@/constants/SigninProcessStep'
import { useRouter } from 'vue-router/dist/vue-router'
import UrlManager from '@/util/UrlManager'
import { useSpinner } from '@/composables/useSpinner'
import OtpUtils from '@/util/OtpUtils'
import SpinnerElement from '@/components/helpers/SpinnerElement'
import OtpInput from '@/components/helpers/otp/OtpInput'

//state
const { t } = useI18n()
const router = useRouter()
const { appContext } = getCurrentInstance()
const signinFlowApi = appContext.config.globalProperties.$signinFlowApi
const {
  errors,
  getLocationError,
  getFieldError,
  setResponseErrors
} = useValidationHandler()
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const email = ref('')
const code = ref('')
const entryOtpInputEl = ref(null)
const secondsBeforeResend = ref(0)
const otpChannel = OtpChannel.EMAIL
const signinProcessService = new SigninProcessService(
  router,
  [
    SigninProcessStep.AUTH_2FA_CODE,
    SigninProcessStep.AUTH_2FA_DEVICE,
    SigninProcessStep.REGISTRATION,
    SigninProcessStep.UPDATE_AGREEMENT,
    SigninProcessStep.LOGIN,
  ]
)

const resendCodeText = computed(() => {
  return unref(secondsBeforeResend) > 0
    ? t('auth.otp.singInOtpPage.sendAgain.timer.title', [unref(secondsBeforeResend)])
    : t('auth.otp.singInOtpPage.sendAgain.ready.title')
})
const otpFieldErrorMessage = computed({
  get () {
    return getFieldError('otp')
  },
  set () {
    const locationError = getLocationError('otp')
    if (locationError) {
      errors.value = unref(errors).filter(el => el.location !== locationError.location)
    }
  }
})

//methods
function handleOtpChange (otp) {
  code.value = otp
  otpFieldErrorMessage.value = ''
}

function handleOtpComplete (otp) {
  handleOtpChange(otp)
  submitOtp()
}

function localizeUrl (path) {
  return UrlManager.localizeUrl('', path)
}

function sendOtpCode () {
  if (unref(secondsBeforeResend) > 0) {
    return
  }

  showSpinner()
  signinFlowApi
    .sendOtp()
    .then((response) => {
      secondsBeforeResend.value = OtpUtils.getResendLeftSeconds(response.data)
      unref(entryOtpInputEl)?.clearInput()
    })
    .catch((error) => setResponseErrors(error.response))
    .finally(() => hideSpinner())
}

function submitOtp () {
  showSpinner()
  signinFlowApi
    .authenticateByOtp({ otp: unref(code) })
    .then((response) => signinProcessService.pushToNextSigninStep(response.data))
    .catch((error) => {
      setResponseErrors(error.response)
      hideSpinner()
    })
}

//watchers
watch(secondsBeforeResend, (seconds) => {
  if (seconds > 0) {
    setTimeout(() => {
      secondsBeforeResend.value--
    }, 1000)
  }
})

//lifecycle
onMounted(() => {
  showSpinner()
  signinFlowApi
    .loadStep({ signinProcessStep: SigninProcessStep.AUTH_OTP })
    .then((response) => {
      email.value = response.data.email
      sendOtpCode()
    })
    .catch(() => router.push({ path: '/' }))
    .finally(() => hideSpinner())
})

</script>
