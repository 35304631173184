<template>
  <div class="ms-language-selector">
    <select-2
      v-model="$i18n.locale"
      :options="$i18n.availableLocales"/>
  </div>
</template>

<style lang="scss">
.ms-language-selector {
  margin: 0;
  @include mq('tablet', min) {
    margin: 0 16px 0 0;
  }
}

.select2-container {
  width: auto !important;
}

.select2-search--dropdown {
  display: none !important;
}

.select2-selection--single {
  border: 0 !important;
  text-transform: uppercase;
  outline: unset;
  height: unset !important;
}

.select2-selection__rendered {
  @include display-flex-important;
  @include align-items(center);
  @include justify-content(center);
  @include border-radius(50%);
  background-color: rgba($header-button-color, .5);
  width: 34px;
  height: 34px;
  padding: 0 !important;
  border: 1px solid $white-text;
  font-family: $origin-fonts-medium;
  font-size: 14px;
  color: $dark-green-color;
  text-transform: uppercase;
}

.select2-dropdown {
  border: 0 !important;
  @include box-shadow(0 1px 8px rgba(0, 0, 0, .12));
}

.select2-container--open .select2-dropdown {
  top: 11px;
  left: -104px !important;
  @include mq('tablet', min) {
    left: -54px !important;
  }
}

.select2-container--open .select2-dropdown--below {
  width: auto !important;
  background: $white-bg;
  @include transition(0.15s);
  @include box-shadow(3px 3px 12px 0 rgba($dark-green-color, .13));
  @include border-radius(12px);
  padding: 5px 8px;

  &:before {
    content: '';
    position: absolute;
    top: -11px;
    width: 100%;
    height: 11px;
  }

  &:after {
    content: '';
    position: absolute;
    top: -6px;
    left: calc(50% + 50px);
    @include transform(translate(-50%, 0%));
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    @include mq('tablet', min) {
      left: 50%;
    }
  }
}

.select2-container--default .select2-results__options {
  @include flexbox;
}

.select2-results__option {
  @include flexbox;
  @include align-items(center);
  @include justify-content(center);
  @include border-radius(50%);
  width: 28px;
  height: 28px;
  background-color: rgba(#C9F4DC, .5);
  box-sizing: border-box;
  margin-right: 5px;
  font-family: $origin-fonts-medium;
  font-size: 10px;
  color: $dark-green-color;
  text-align: center;
  text-transform: uppercase;
  @include transition(0.15s);

  &:hover,
  &[aria-selected=true] {
    color: $dark-green-color !important;
    background-color: rgba(#9DEAD4, .8) !important;
  }

  &:last-child {
    margin: 0;
  }
}

.select2-results__option--highlighted {
  color: $dark-green-color !important;
  background-color: rgba(#C9F4DC, .5) !important;
}

.select2-selection__arrow {
  display: none !important;
}

.select2-container--default .select2-selection--single {
  background: transparent !important;
}
</style>
