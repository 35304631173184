<template>
  <div class="ms-form-content-wrapper">
    <div
      v-if="isCookieEnabled"
      class="ms-login-with-list">
      <a :href="getAuthLink()">
        <icon-logo-microsoft/>
        {{ $t('auth.oidcLogin.authPage.login.microsoft') }}
      </a>
    </div>
    <div
      v-else
      class="ms-information-block">
      {{ $t('auth.oidcLogin.authPage.login.error.cookiesOff') }}
    </div>
  </div>
</template>

<script setup>
import IconLogoMicrosoft from '@/components/icons/IconLogoMicrosoft.vue'
import { OidcProvider } from '@/constants/OidcProvider'

//properties
const props = defineProps({
  authLink: String
})

//state
const isCookieEnabled = navigator.cookieEnabled

//methods
function getAuthLink () {
  if (props.authLink) {
    return props.authLink
  }
  return OidcProvider.MS_GOOGLE.authLink
}
</script>

<style lang="scss" scoped>

</style>
