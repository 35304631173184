import { ref, unref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useValidationHandler () {
  const errors = ref([])
  const { t } = useI18n()

  function getLocationError (field) {
    if (!unref(errors) || !field) {
      return null
    }

    return unref(errors).find((el) => {
      if (el.location === field) {
        return el
      }
    })
  }

  function getFieldError (field) {
    if (!unref(errors)) {
      return null
    }

    const it = unref(errors).find((el) => {
      if (el.location === field) {
        return true
      }
    })
    if (it) {
      return t(it.userMessageCode)
    }
  }

  function setResponseErrors (response) {
    errors.value = response.data.errors
  }

  return {
    errors,
    getLocationError,
    getFieldError,
    setResponseErrors
  }
}
