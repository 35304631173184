<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner-element :loading="isLoading"/>
      <div class="ms-otp-form-title">{{ $t('auth.enableTwoFactorAuthSuccess.title') }}</div>
      <div class="ms-otp-caption">{{ $t('auth.enableTwoFactorAuthSuccess.description') }}</div>
      <form
        @submit.prevent="enable2FASuccess"
        id="enable2FASuccessForm"
        autocomplete="off">
        <div class="ms-otp-btn-wrap">
          <input
            type="submit"
            class="ms-otp-btn"
            id="submit"
            :value="$t('default.next.title')">
        </div>
      </form>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp"
        alt="login image">
    </div>
  </div>
</template>

<script setup>
import { SigninProcessStep } from '@/constants/SigninProcessStep'
import { SigninProcessService } from '@/service/SigninProcessService'
import { getCurrentInstance, onMounted } from 'vue'
import SpinnerElement from '@/components/helpers/SpinnerElement'
import { useSpinner } from '@/composables/useSpinner'
import { useRouter } from 'vue-router'

//state
const { appContext } = getCurrentInstance()
const signinFlowApi = appContext.config.globalProperties.$signinFlowApi
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const router = useRouter()
const signinProcessService = new SigninProcessService(
  router,
  [
    SigninProcessStep.LOGIN,
  ]
)

//methods
function enable2FASuccess () {
  showSpinner()
  signinFlowApi
    .enable2FASuccess()
    .then((response) => signinProcessService.pushToNextSigninStep(response.data))
    .catch(() => hideSpinner())
}

//lifecycle
onMounted(() => {
  showSpinner()
  signinFlowApi
    .loadStep({ signinProcessStep: SigninProcessStep.ENABLE_2FA_SUCCESS })
    .catch(() => router.push({ path: '/' }))
    .finally(() => hideSpinner())
})
</script>

<style lang="scss" scoped>
.ms-otp-form-title {
  @include mq('desktop', min) {
    font-size: 36px;
  }
}
</style>
