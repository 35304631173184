<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner-element :loading="isLoading"/>
      <div class="ms-otp-registration-title-wrapper">
        <div class="ms-otp-form-title">{{ $t('auth.updatePassword.title') }}</div>
        <div class="ms-otp-caption">{{ $t('auth.updatePassword.description') }}</div>
      </div>
      <form
        @submit.prevent="submitUpdatePassword"
        id="updatePasswordStepForm">
        <div class="ms-otp-form-content-wrapper">
          <div :class="[passwordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <div class="ms-otp-input-password-wrap">
              <input
                v-if="showPassword"
                class="ms-otp-input ms-otp-input-password"
                type="text"
                name="password"
                :placeholder="$t('auth.patientRegistration.placeholder.password')"
                v-model="password"
                @focus="passwordFieldErrorMessage = null"
                autocomplete="new-password">
              <input
                v-else
                type="password"
                class="ms-otp-input ms-otp-input-password"
                :placeholder="$t('auth.login.password.placeholder')"
                v-model="password"
                @focus="passwordFieldErrorMessage = null"
                autocomplete="new-password">
              <div
                :class="{ 'ms-eye': showPassword, 'ms-eye-slash': !showPassword }"
                class="ms-show-password-btn"
                @click="togglePasswordShow">
                <icon-eye-slashed/>
                <icon-eye/>
              </div>
            </div>
            <span
              v-if="passwordFieldErrorMessage"
              class="ms-otp-error-text">{{ passwordFieldErrorMessage }}</span>
          </div>
          <div :class="[confirmPasswordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <div class="ms-otp-input-password-wrap">
              <input
                v-if="showConfirmPassword"
                class="ms-otp-input ms-otp-input-password"
                type="text"
                name="password"
                :placeholder="$t('auth.patientRegistration.placeholder.confirmPassword')"
                v-model="confirmPassword"
                @focus="confirmPasswordFieldErrorMessage = null"
                autocomplete="new-password">
              <input
                v-else
                type="password"
                class="ms-otp-input ms-otp-input-password"
                :placeholder="$t('auth.login.confirmPassword.placeholder')"
                v-model="confirmPassword"
                @focus="confirmPasswordFieldErrorMessage = null"
                autocomplete="new-password">
              <div
                :class="{ 'ms-eye': showConfirmPassword, 'ms-eye-slash': !showConfirmPassword }"
                class="ms-show-password-btn"
                @click="toggleConfirmPasswordShow">
                <icon-eye-slashed/>
                <icon-eye/>
              </div>
            </div>
            <span
              v-if="confirmPasswordFieldErrorMessage"
              class="ms-otp-error-text">{{ confirmPasswordFieldErrorMessage }}</span>
          </div>
        </div>
        <div class="ms-otp-btn-wrap">
          <router-link
            :to="{ path: localizeUrl('', '/') }"
            class="ms-otp-btn ms-otp-btn-outline">{{ $t('default.back.title') }}
          </router-link>
          <input
            type="submit"
            class="ms-otp-btn"
            :value="$t('auth.updatePassword.button')"/>
        </div>
      </form>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp"
        alt="login image">
    </div>
  </div>
</template>

<script setup>
import { useSpinner } from '@/composables/useSpinner'
import SpinnerElement from '@/components/helpers/SpinnerElement'
import IconEyeSlashed from '@/components/icons/IconEyeSlashed'
import IconEye from '@/components/icons/IconEye'
import { computed, getCurrentInstance, onMounted, ref, unref } from 'vue'
import { useRouter } from 'vue-router'
import { SigninProcessStep } from '@/constants/SigninProcessStep'
import { SigninProcessService } from '@/service/SigninProcessService'
import UrlManager from '@/util/UrlManager'
import { useValidationHandler } from '@/composables/useValidationHandler'

//state
const router = useRouter()
const { appContext } = getCurrentInstance()
const signinFlowApi = appContext.config.globalProperties.$signinFlowApi
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const {
  errors,
  getLocationError,
  getFieldError,
  setResponseErrors
} = useValidationHandler()
const email = ref('')
const password = ref('')
const confirmPassword = ref('')
const showPassword = ref(false)
const showConfirmPassword = ref(false)
const signinProcessService = new SigninProcessService(
  router,
  [
    SigninProcessStep.UPDATE_AGREEMENT,
    SigninProcessStep.LOGIN,
  ]
)
const passwordFieldErrorMessage = computed({
  get () {
    return getFieldError('password')
  },
  set () {
    const locationError = getLocationError('password')
    if (locationError) {
      errors.value = unref(errors).filter(el => el.location !== locationError.location)
    }
  }
})
const confirmPasswordFieldErrorMessage = computed({
  get () {
    return getFieldError('confirmPassword')
  },
  set () {
    const locationError = getLocationError('confirmPassword')
    if (locationError) {
      errors.value = unref(errors).filter(el => el.location !== locationError.location)
    }
  }
})

//methods
function submitUpdatePassword () {
  const params = {
    password: unref(password),
    confirmPassword: unref(confirmPassword),
  }

  showSpinner()
  signinFlowApi
    .updatePassword(params)
    .then((response) => signinProcessService.pushToNextSigninStep(response.data))
    .catch((error) => {
      setResponseErrors(error.response)
      hideSpinner()
    })
}

function localizeUrl (host, path) {
  return UrlManager.localizeUrl(host, path)
}

function toggleConfirmPasswordShow () {
  showConfirmPassword.value = !unref(showConfirmPassword)
}

function togglePasswordShow () {
  showPassword.value = !unref(showPassword)
}

//lifecycle
onMounted(() => {
  showSpinner()
  signinFlowApi
    .loadStep({ signinProcessStep: SigninProcessStep.UPDATE_PASSWORD })
    .then((response) => email.value = response.data.email)
    .catch(() => router.push({ path: '/' }))
    .finally(() => hideSpinner())
})
</script>

<style lang="scss" scoped>
.ms-otp-form-content-wrapper {
  @include flexbox;
  @include flex-direction(column);
}

.ms-otp-form-row {
  margin-bottom: 16px;

  &:last-child {
    margin: 0;
  }
}

.ms-otp-form-content-title {
  font-family: $origin-fonts-bold;
  font-size: 14px;
  color: $dark-green-color;
  margin-bottom: 21px;
}

.ms-otp-checkbox-wrap {
  position: relative;
}

.ms-otp-tooltip-pw {
  position: absolute;
  right: -30px;

  &.active {
    .ms-tooltip-text {
      display: block;
      opacity: 1;
      visibility: visible;
      @include mq('tablet', min) {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  @include mq('tablet', min) {
    &:hover {
      .ms-tooltip-text {
        display: block;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .ms-tooltip-text {
    position: absolute;
    top: 30px;
    left: unset;
    right: -44px;
    display: none;
    width: 300px;
    background-color: #FFFFFF;
    padding: 4px 6px;
    font-family: $origin-fonts-regular;
    font-size: 10px;
    opacity: 0;
    visibility: hidden;
    @include box-shadow(0px 2px 10px rgba(0, 0, 0, 0.2));
    @include border-radius(6px);
    @include transition(.3s);
    z-index: 2;
    @include mq('desktop', min) {
      left: -6px;
    }

    &:before {
      content: " ";
      position: absolute;
      top: -12px;
      left: unset;
      right: 48px;
      border-top: 6px solid transparent;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #FFFFFF;
      @include mq('desktop', min) {
        left: 10px;
      }
    }
  }
}
</style>
