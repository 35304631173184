<!--
use example:
<spinner-element :loading="true" :color="#000" minHeight="100px"/>
props:
  position: relative | absolute
-->
<template>
  <div v-show="loading" :style="[styleHandler()]" class="ms-spinner-wrap">
    <div class="ms-spinner">
      <img src="@/assets/images/spinner/spinner.svg" alt=""/>
    </div>
  </div>

</template>

<script setup>
const props = defineProps({
  loading: {
    type: Boolean,
    default: false
  },
  position: {
    type: String,
    default: 'absolute' // absolute | relative
  },
  minHeight: {
    type: String,
    default: '100px'
  }
})
const spinnerWrapperStyle = {
  position: props.position
}
if (props.position === 'relative') {
  spinnerWrapperStyle.height = props.minHeight
}

//methods
function styleHandler () {
  if (props.position === 'absolute') {
    spinnerWrapperStyle.top = '0'
    spinnerWrapperStyle.left = '0'
    spinnerWrapperStyle.right = '0'
    spinnerWrapperStyle.bottom = '0'
  }
  return spinnerWrapperStyle
}
</script>

<style lang="scss" scoped>
.ms-spinner {
  @include transition(all 0.3s ease-in-out 0);
  @include animation(ms-scaleout 1.0s infinite ease-in-out);

  &-wrap {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    background: rgba(255, 255, 255, 0.8);
    z-index: 100;
  }

  img {
    display: block;
    width: auto;
    height: 36px;
    margin: 0 auto;
  }

}

@include keyframes(ms-scaleout) {
  0% {
    @include scale(0);
    opacity: 1;
  }
  100% {
    @include scale(1.0);
    opacity: 0;
  }
}

</style>
