<template>
  <div class="ms-otp-input-row">
    <input @change="handleOnChange"
           autocomplete="one-time-code"
           inputmode="numeric"
           type="text"
           style="display:none;">
    <single-otp-input
      v-for="(item, i) in numInputs"
      :key="i"
      :focus="activeInput === i"
      :value="otp[i]"
      :separator="separator"
      :input-type="inputType"
      :input-classes="inputClasses"
      :input-mode="inputMode"
      :is-last-child="i === numInputs - 1"
      :should-auto-focus="shouldAutoFocus"
      @on-change="handleChangeValue"
      @on-keydown="handleOnKeyDown"
      @on-paste="handlePasteValue"
      @on-focus="handleOnFocus(i)"
      @on-blur="handleOnBlur"
    />
  </div>
</template>

<script setup>
// source code https://github.com/bachdgvn/vue-otp-input

import SingleOtpInput from './SingleOtpInput.vue'
import { OtpChannel } from '@/constants/OtpChannel'
import { onMounted, ref, unref } from 'vue'

// keyCode constants
const BACKSPACE = 8
const LEFT_ARROW = 37
const RIGHT_ARROW = 39
const DELETE = 46

const props = defineProps({
  numInputs: {
    default: 6,
  },
  separator: {
    type: String,
    default: '',
  },
  inputClasses: {
    type: String,
  },
  inputType: {
    type: String,
    validator (value) {
      return ['number', 'tel', 'password'].includes(value)
    },
  },
  inputMode: {
    type: String,
    validator (value) {
      return ['text', 'numeric', 'tel', 'none'].includes(value)
    },
  },
  shouldAutoFocus: {
    type: Boolean,
    default: true,
  },
  channel: {
    type: String,
    default: OtpChannel.EMAIL,
  }
})

//state
const activeInput = ref(0)
const otp = ref([])
const oldOtp = ref([])

//emits
const emit = defineEmits(['on-complete', 'on-change'])

//methods
function handleOnFocus (index) {
  activeInput.value = index
}

function handleOnBlur () {
  activeInput.value = -1
}

// Helper to return OTP from input
function checkFilledAllInputs () {
  if (unref(otp).join('').length === props.numInputs) {
    return emit('on-complete', unref(otp).join(''))
  }
  return 'Wait until the user enters the required number of characters'
}

// Focus on input by index
function focusInput (input) {
  activeInput.value = Math.max(Math.min(props.numInputs - 1, input), 0)
}

// Focus on next input
function focusNextInput () {
  focusInput(unref(activeInput) + 1)
}

// Focus on previous input
function focusPrevInput () {
  focusInput(unref(activeInput) - 1)
}

// Change OTP value at focused input
function changeCodeAtFocus (value) {
  oldOtp.value = [...otp.value]
  otp.value[activeInput.value] = value
  if (unref(oldOtp).join('') !== unref(otp).join('')) {
    emit('on-change', unref(otp).join(''))
    checkFilledAllInputs()
  }
}

function handlePasteValue (value) {
  const clearedValue = value.replace(/\D/g, '')
    .slice(0, props.numInputs - unref(activeInput))
    .split('')

  // Paste data from focused input onwards
  const currentCharsInOtp = unref(otp).slice(0, unref(activeInput))
  const combinedWithPastedData = currentCharsInOtp.concat(clearedValue)
  otp.value = combinedWithPastedData.slice(0, props.numInputs)
  focusInput(combinedWithPastedData.slice(0, props.numInputs).length)
  return checkFilledAllInputs()
}

function handleOnChange (e) {
  const value = e.target.value
  if (!value) {
    e.preventDefault()
  } else if (value.length > 1) {
    handlePasteValue(value)
  } else {
    handleChangeValue(value)
  }
}

function handleChangeValue (value) {
  changeCodeAtFocus(value)
  focusNextInput()
}

function clearInput () {
  if (unref(otp).length > 0) {
    emit('on-change', '')
  }
  otp.value = []
  activeInput.value = 0
}

// Handle cases of backspace, delete, left arrow, right arrow
function handleOnKeyDown (event) {
  switch (event.keyCode) {
    case BACKSPACE:
      event.preventDefault()
      changeCodeAtFocus('')
      focusPrevInput()
      break
    case DELETE:
      event.preventDefault()
      changeCodeAtFocus('')
      break
    case LEFT_ARROW:
      event.preventDefault()
      focusPrevInput()
      break
    case RIGHT_ARROW:
      event.preventDefault()
      focusNextInput()
      break
    default:
      break
  }
}

//lifecycle
onMounted(() => {
  if (props.channel === OtpChannel.SMS && 'OTPCredential' in window) {
    navigator.credentials
      .get({
        otp: { transport: ['sms'] },
      })
      .then((receivedOtp) => {
        const receivedOtpCode = (receivedOtp.code).toString()
        otp.value = receivedOtpCode.split('')
        focusInput(receivedOtpCode.length)
        return checkFilledAllInputs()
      })
      .catch((error) => console.error(error))
  }
})

defineExpose({
  clearInput,
})
</script>

<style scoped lang="scss">
.ms-otp-input-row {
  @include flexbox;
}
</style>
