<template>
  <svg width="208" height="28" viewBox="0 0 208 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.1276 13.8365V27.1193H11.5302V16.9727C11.5302 14.9434 11.2535 13.5598 10.7 12.7296C10.1466 11.8995 9.22414 11.5305 7.84052 11.5305C7.10259 11.5305 6.36466 11.715 5.81121 12.0839C5.16552 12.4529 4.42759 13.0064 3.68966 13.9288V27.2115H0V8.76325H3.59741V11.1615C5.44224 9.3167 7.19483 8.39429 9.03966 8.39429C11.3457 8.39429 13.1905 9.50118 14.3897 11.715C16.3267 9.50118 18.356 8.39429 20.4776 8.39429C22.2302 8.39429 23.706 9.03998 24.8129 10.3314C25.9198 11.6227 26.5655 13.652 26.5655 16.2348V27.027H22.9681V16.2348C22.9681 14.7589 22.6914 13.5598 22.0457 12.7296C21.4 11.8995 20.5698 11.5305 19.3707 11.5305C17.8026 11.5305 16.419 12.2684 15.1276 13.8365Z" fill="#32B1AB" />
    <path d="M70.104 1.10669C70.6574 1.10669 71.2109 1.29117 71.6721 1.75238C72.1333 2.12134 72.3178 2.67479 72.3178 3.22824C72.3178 3.78169 72.1333 4.33514 71.6721 4.7041C71.2109 5.16531 70.7497 5.34979 70.104 5.34979C69.5505 5.34979 69.0893 5.16531 68.6281 4.7041C68.1669 4.2429 67.9824 3.78169 67.9824 3.22824C67.9824 2.67479 68.1669 2.21359 68.6281 1.75238C68.9971 1.29117 69.5505 1.10669 70.104 1.10669ZM68.2591 8.76273H71.8566V27.0265H68.2591V8.76273Z" fill="#32B1AB" />
    <path d="M89.0127 23.1525C87.5368 23.7059 86.1532 24.0749 85.0463 24.0749C83.1092 24.0749 81.6334 23.5214 80.4342 22.4145C79.3273 21.3076 78.7739 19.8318 78.7739 17.9869C78.7739 16.2344 79.3273 14.7585 80.342 13.6516C81.3566 12.5447 82.7403 11.899 84.3084 11.899C85.7842 11.899 87.3523 12.4525 89.0127 13.3749V9.59298C88.0903 9.22401 87.2601 8.94729 86.6144 8.76281C85.8765 8.57833 85.0463 8.48608 84.1239 8.48608C81.4489 8.48608 79.2351 9.4085 77.5747 11.1611C75.9144 12.9137 75.0842 15.2197 75.0842 18.0792C75.0842 20.9387 75.9144 23.1525 77.667 24.9051C79.3273 26.5654 81.6334 27.4878 84.4928 27.4878C85.9687 27.4878 87.5368 27.2111 89.0127 26.7499V23.1525Z" fill="#32B1AB" />
    <path d="M60.6953 0V9.03966C59.404 8.57845 58.2048 8.39397 57.0979 8.39397C54.5152 8.39397 52.3014 9.22414 50.641 10.9767C48.9807 12.7293 48.1505 14.9431 48.1505 17.6181C48.1505 26.5655 55.2531 27.3035 58.666 27.3035C60.1419 27.3035 62.7247 27.119 64.385 26.6578V0H60.6953ZM58.2971 24.075C56.2678 24.075 54.6996 23.5216 53.5928 22.4147C52.3936 21.3078 51.8402 20.0164 51.8402 18.0793C51.8402 16.05 52.3936 14.4819 53.4083 13.2828C54.5152 12.0836 55.8988 11.5302 57.7436 11.5302C58.7583 11.5302 59.7729 11.7147 60.7876 12.1759V23.706C59.8652 23.8905 59.035 24.075 58.2971 24.075Z" fill="#32B1AB" />
    <path d="M45.936 17.7107C45.936 14.9434 45.198 12.6374 43.8144 10.977C42.3385 9.3167 40.4015 8.39429 38.0032 8.39429C35.5127 8.39429 33.4834 9.22446 31.823 10.977C30.2549 12.7296 29.4247 14.9434 29.4247 17.8029C29.4247 19.6477 29.7937 21.3081 30.5316 22.6917C31.2696 24.0753 32.2842 25.1822 33.4834 26.0124C34.7747 26.8426 36.3428 27.3038 38.3722 27.3038C39.848 27.3038 41.1394 27.1193 42.2463 26.8426C43.3532 26.5658 44.4601 26.0124 45.567 25.2745V21.7693C43.5377 23.2452 41.3239 23.9831 39.0178 23.9831C37.2653 23.9831 35.8816 23.4296 34.867 22.415C33.8523 21.4003 33.2989 20.1089 33.1144 18.5408H45.936V18.2641V17.8029V17.7107ZM34.7747 12.7296C35.6049 11.9917 36.6196 11.5305 38.0032 11.5305C39.2946 11.5305 40.3092 11.8995 41.1394 12.7296C41.8773 13.4676 42.2463 14.4822 42.4308 15.8658H33.2066C33.4834 14.4822 34.0368 13.4676 34.7747 12.7296Z" fill="#32B1AB" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M100.728 8.20923C106.263 8.20923 110.413 12.3601 110.413 17.8946C110.413 23.4291 106.263 27.5799 100.728 27.5799C95.1936 27.5799 91.0427 23.4291 91.0427 17.8946C91.0427 12.3601 95.1936 8.20923 100.728 8.20923ZM102.204 19.186H106.447V16.3265H102.204V11.8989H99.252V16.3265H95.0089V19.186H99.252V23.6135H102.204V19.186Z" fill="#32B1AB" />
    <path d="M113.181 25.7355V21.8614C114.195 22.5993 115.21 23.1528 116.317 23.614C117.424 24.0752 118.254 24.2597 118.992 24.2597C119.73 24.2597 120.376 24.0752 120.929 23.7062C121.482 23.3372 121.759 22.876 121.759 22.4148C121.759 21.8614 121.575 21.4002 121.206 21.1234C120.837 20.7545 120.099 20.2933 118.9 19.6476C116.594 18.3562 115.025 17.2493 114.288 16.3269C113.55 15.4045 113.181 14.3898 113.181 13.2829C113.181 11.8993 113.734 10.7002 114.841 9.86999C115.948 8.94758 117.332 8.57861 119.084 8.57861C120.837 8.57861 122.682 9.03982 124.619 10.0545V13.6519C122.497 12.3605 120.744 11.7148 119.361 11.7148C118.623 11.7148 118.069 11.8993 117.7 12.176C117.239 12.4528 117.055 12.914 117.055 13.3752C117.055 13.8364 117.239 14.2053 117.608 14.5743C117.977 14.9433 118.715 15.4045 119.638 15.9579L120.929 16.6959C123.973 18.4484 125.541 20.2933 125.541 22.4148C125.541 23.8907 124.988 25.1821 123.788 26.1045C122.589 27.0269 121.113 27.5803 119.269 27.5803C118.162 27.5803 117.239 27.4881 116.409 27.2114C115.487 26.8424 114.472 26.3812 113.181 25.7355Z" fill="#32B1AB" />
    <path d="M177.196 9.59342C176.089 8.76325 174.982 8.39429 173.967 8.39429C170.093 8.39429 168.525 11.9917 168.525 11.9917V8.76325H164.928V27.027H168.525V17.5262C168.525 15.9581 168.986 14.6667 169.817 13.5598C170.647 12.4529 171.661 11.8995 172.86 11.8995C173.598 11.8995 174.336 12.1762 175.167 12.7296L177.196 9.59342Z" fill="#32B1AB" />
    <path d="M189.833 23.1525C188.357 23.7059 186.973 24.0749 185.867 24.0749C183.929 24.0749 182.454 23.5214 181.254 22.4145C180.148 21.3076 179.594 19.8318 179.594 17.9869C179.594 16.2344 180.148 14.7585 181.162 13.6516C182.177 12.5447 183.56 11.899 185.221 11.899C186.697 11.899 188.265 12.4525 189.925 13.3749V9.59298C189.003 9.22401 188.173 8.94729 187.527 8.76281C186.789 8.57833 185.959 8.48608 185.036 8.48608C182.361 8.48608 180.148 9.4085 178.487 11.1611C176.827 12.9137 175.997 15.2197 175.997 18.0792C175.997 20.9387 176.827 23.1525 178.579 24.9051C180.24 26.5654 182.546 27.4878 185.405 27.4878C186.881 27.4878 188.449 27.2111 189.925 26.7499V23.1525H189.833Z" fill="#32B1AB" />
    <path d="M192.416 0.829834H196.013V11.1609C197.489 9.31604 199.426 8.39363 201.64 8.39363C202.839 8.39363 203.946 8.67035 204.961 9.31604C205.883 9.96173 206.621 10.7919 207.082 11.8066C207.543 12.9135 207.82 14.4816 207.82 16.6031V27.0264H204.223V15.7729C204.223 14.3893 203.854 13.3747 203.208 12.5445C202.562 11.7143 201.732 11.3454 200.625 11.3454C199.795 11.3454 199.057 11.5298 198.411 11.991C197.674 12.36 196.936 13.0979 196.105 14.0204V27.1186H192.508V0.829834H192.416Z" fill="#32B1AB" />
    <path d="M144.266 17.7107C144.266 14.9434 143.528 12.6374 142.144 10.977C140.668 9.3167 138.731 8.39429 136.333 8.39429C133.842 8.39429 131.813 9.22446 130.153 10.977C128.585 12.7296 127.754 14.9434 127.754 17.8029C127.754 19.6477 128.123 21.3081 128.861 22.6917C129.599 24.0753 130.614 25.1822 131.813 26.0124C133.104 26.8426 134.672 27.3038 136.702 27.3038C138.178 27.3038 139.469 27.1193 140.576 26.8426C141.683 26.5658 142.79 26.0124 143.897 25.2745V21.7693C141.867 23.2452 139.653 23.9831 137.347 23.9831C135.595 23.9831 134.211 23.4296 133.197 22.415C132.182 21.4003 131.628 20.1089 131.444 18.5408H144.266V18.2641V17.8029V17.7107ZM133.104 12.7296C133.935 11.9917 134.949 11.5305 136.333 11.5305C137.624 11.5305 138.639 11.8995 139.469 12.7296C140.207 13.4676 140.576 14.4822 140.76 15.8658H131.628C131.813 14.4822 132.366 13.4676 133.104 12.7296Z" fill="#32B1AB" />
    <path d="M161.331 15.4964C161.331 11.0688 159.117 8.48608 154.32 8.48608C152.014 8.48608 149.432 9.31626 148.14 9.96195V13.6516C149.708 12.6369 151.83 11.7145 153.859 11.7145C156.073 11.7145 157.733 12.8214 157.733 15.4042C155.243 15.7732 146.388 16.3266 146.388 22.3223C146.388 24.8128 148.14 27.3956 153.213 27.3956C156.534 27.3956 159.209 26.6576 161.331 25.8275V15.4964ZM157.733 18.1714V23.6137C156.442 24.0749 155.427 24.3516 153.767 24.3516C151.922 24.3516 149.985 23.5214 149.985 21.7688C149.985 19.1861 155.151 18.3559 157.733 18.1714Z" fill="#32B1AB" />
  </svg>
</template>
