import { ref, unref } from 'vue'

export function useSpinner() {
  const isLoading = ref(false)
  const loaderCount = ref(0)

  function loadingIncrement() {
    loaderCount.value++
    if (!unref(isLoading)) {
      isLoading.value = true
    }
  }

  function loadingDecrement() {
    loaderCount.value--
    if (unref(loaderCount) === 0) {
      isLoading.value = false
    }
  }

  return {
    isLoading,
    showSpinner: loadingIncrement,
    hideSpinner: loadingDecrement
  }
}
