<template>
  <not-found-page v-if="isPageNotFound"/>
  <router-view v-else/>
</template>

<script setup>
import {useStore} from '@/stores/useStore';
import {computed} from "vue";
import NotFoundPage from "@/views/error/NotFoundPage";

//state
const store = useStore();
const isPageNotFound = computed(() => store.$state.isPageNotFound)

</script>
