<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner-element :loading="isLoading"/>
      <h1 class="ms-otp-form-title">{{ $t('auth.otp.singInPage.title') }}</h1>
      <div class="ms-otp-caption">{{ $t('auth.otp.singInPage.description') }}</div>
      <form
        @submit.prevent="submitRequestSignin"
        id="emailStepForm"
        class="ms-otp-form-wrap">
        <div :class="[emailFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
          <div class="ms-otp-input-email-wrap">
            <input
              type="email"
              name="email"
              ref="emailEl"
              class="ms-otp-input" id="ms-otp-input-email-sign-in"
              :placeholder="$t('auth.otp.singInPage.email.placeholder')"
              v-model="email"
              @focus="emailFieldErrorMessage = null"
              autocomplete="username"
            />
          </div>
          <span
            v-if="emailFieldErrorMessage"
            class="ms-otp-error-text">{{ emailFieldErrorMessage }}</span>
        </div>
        <div id="msp-captcha-box"></div>
        <div
          v-if="captchaFieldErrorMessage"
          class="ms-otp-error-field">
          <span class="ms-otp-error-text">{{ captchaFieldErrorMessage }}</span>
        </div>

        <div class="ms-otp-btn-wrap">
          <input
            type="submit"
            class="ms-otp-btn"
            id="submit"
            :disabled="submitLocked"
            :value="$t('default.next.title')"/>
        </div>

        <input
          v-if="autofillType === 'ios'"
          type="password" name="password" autocomplete="password"
          class="mosparo__ignored-field"
          style="width: 0;height: 0;border-width: 0;"/>
      </form>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp"
        alt="login image">
    </div>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router'
import { computed, getCurrentInstance, onMounted, ref, unref } from 'vue'
import { SigninProcessService } from '@/service/SigninProcessService'
import { SigninProcessStep } from '@/constants/SigninProcessStep'
import UserDataUtils from '@/util/UserDataUtils'
import SpinnerElement from '@/components/helpers/SpinnerElement'
import { AuthenticationRequestType } from '@/constants/AuthenticationRequestType'
import { useValidationHandler } from '@/composables/useValidationHandler'
import { useSpinner } from '@/composables/useSpinner'
import { captchaHandler } from '@/util/CaptchaHandler'

//state
const router = useRouter()
const route = useRoute()
const { appContext } = getCurrentInstance()
const signinFlowApi = appContext.config.globalProperties.$signinFlowApi
const signinProcessService = new SigninProcessService(
  router,
  [
    SigninProcessStep.AUTH_OTP,
    SigninProcessStep.AUTH_PASSWORD,
    SigninProcessStep.AUTH_OIDC,
  ]
)
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const {
  errors,
  getLocationError,
  getFieldError,
  setResponseErrors
} = useValidationHandler()
const {
  captchaCheckAndCall,
  getCaptchaSubmitToken,
  getCaptchaValidationToken
} = captchaHandler(signinFlowApi)

const submitLocked = ref(false)

const email = ref('')
const emailEl = ref(null)

const autofillType = computed(() => route.query?.autofillType)
const emailFieldErrorMessage = computed({
  get () {
    return getFieldError('email')
  },
  set () {
    const locationError = getLocationError('email')
    if (locationError) {
      errors.value = unref(errors).filter(el => el.location !== locationError.location)
    }
  }
})

const captchaFieldErrorMessage = computed({
  get () {
    return getFieldError('captcha')
  },
  set () {
    const locationError = getLocationError('captcha')
    if (locationError) {
      errors.value = unref(errors).filter(el => el.location !== locationError.location)
    }
  }
})

//methods
function submitRequestSignin() {
  if (submitLocked.value) {
    return
  }
  showSpinner()
  lockSubmit()
  captchaCheckAndCall(AuthenticationRequestType.SIGNIN_INFO_REQUEST, submitForm)
    .catch((error) => {
      setResponseErrors(error.response)
      hideSpinner()
    })
    .finally(unlockSubmit)
}

function submitForm() {
  const captchaSubmitToken = getCaptchaSubmitToken()
  const captchaValidationToken = getCaptchaValidationToken()
  const params = {
    email: unref(email),
    withOidc: true,
    captchaSubmitToken,
    captchaValidationToken
  }
  signinFlowApi.requestSignin(params)
    .then((response) => {
      UserDataUtils.setEmail(unref(email))
      signinProcessService.pushToNextSigninStep(response.data)
    })
    .catch((error) => {
      setResponseErrors(error.response)
      hideSpinner()
    })
}

function lockSubmit() {
  submitLocked.value = true
}

function unlockSubmit() {
  submitLocked.value = false
}

//lifecycle
onMounted(() => {
  if (route.query?.email) {
    email.value = route.query.email
    router.replace({
      query: {
        ...route.query,
        email: undefined
      }
    })
  }

  unref(emailEl)?.focus()
  showSpinner()
  UserDataUtils.deleteEmail()
  signinFlowApi
    .loadStep({ signinProcessStep: SigninProcessStep.REQUEST_SIGNIN })
    .catch(() => router.push({ path: '/' }))
    .finally(() => hideSpinner())
})

</script>

<style lang="scss" scoped>
.ms-otp-error-field .ms-otp-input-email-wrap label {
  border-right: 1px solid $error-color;
}
</style>
