<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner-element :loading="isLoading"/>
      <div class="ms-otp-form-title">{{ $t('auth.enableTwoFactorAuthPhone.title') }}</div>
      <div class="ms-otp-caption">{{ $t('auth.enableTwoFactorAuthPhone.description') }}</div>
      <form
        @submit.prevent="pushToNextStep"
        id="enable2FAForm"
        autocomplete="off">
        <div class="ms-otp-form-row">
          <div :class="[phoneNumberFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <input
              class="ms-otp-input"
              type="tel"
              name="phoneNumber"
              :placeholder="$t('auth.enableTwoFactorAuthPhone.phoneNumber')"
              v-model="phoneNumber"
              @focus="phoneNumberFieldErrorMessage = null"
              @input="filterPhoneInput"
              @keydown="handlePhoneKeydown"
              @paste="handlePhonePaste"/>
            <span
              v-if="phoneNumberFieldErrorMessage"
              class="ms-otp-error-text">{{ phoneNumberFieldErrorMessage }}</span>
          </div>
        </div>
        <div class="ms-otp-btn-wrap">
          <input
            type="button"
            class="ms-otp-btn ms-otp-btn-outline"
            @click="cancelEnable2FA"
            :value="$t('default.cancel.title')"/>
          <input
            type="submit"
            class="ms-otp-btn"
            id="submit"
            :value="$t('default.next.title')">
        </div>
      </form>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp"
        alt="login image">
    </div>
  </div>
</template>

<script setup>
import { useSpinner } from '@/composables/useSpinner'
import { useRouter } from 'vue-router'
import { computed, getCurrentInstance, onMounted, ref, unref } from 'vue'
import { SigninProcessStep } from '@/constants/SigninProcessStep'
import { SigninProcessService } from '@/service/SigninProcessService'
import { useStore } from '@/stores/useStore'
import SpinnerElement from '@/components/helpers/SpinnerElement'
import { useValidationHandler } from '@/composables/useValidationHandler'

//state
const { appContext } = getCurrentInstance()
const signinFlowApi = appContext.config.globalProperties.$signinFlowApi
const router = useRouter()
const store = useStore()
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const {
  errors,
  getLocationError,
  getFieldError,
  setResponseErrors
} = useValidationHandler()
const phoneNumber = ref('+41')
const signinProcessService = new SigninProcessService(
  router,
  [
    SigninProcessStep.LOGIN,
  ]
)
const phoneNumberFieldErrorMessage = computed({
  get () {
    return getFieldError('phoneNumber')
  },
  set () {
    const locationError = getLocationError('phoneNumber')
    if (locationError) {
      errors.value = unref(errors).filter(el => el.location !== locationError.location)
    }
  }
})

//methods
function pushToNextStep () {
  showSpinner()
  const params = {
    phoneNumber: unref(phoneNumber)
  }
  signinFlowApi
    .sendEnable2FACode(params)
    .then(() => {
      store.setEnable2FAPhoneNumber(unref(phoneNumber))
      router.push({ path: '/signin/2fa/enable/code' })
    })
    .catch((error) => {
      setResponseErrors(error.response)
      hideSpinner()
    })
}

function cancelEnable2FA () {
  showSpinner()
  signinFlowApi
    .cancel2FA()
    .then((response) => signinProcessService.pushToNextSigninStep(response.data))
    .catch((error) => {
      setResponseErrors(error.response)
      hideSpinner()
    })
}

function filterPhoneInput () {
  phoneNumber.value = unref(phoneNumber).replace(/[^\d+]/g, '').replace(/(^\+)|(\+)/g, (match, p1) => p1 ? '+' : '')
}

function handlePhoneKeydown (event) {
  // Allow key combinations with Ctrl or Shift (like Ctrl+C, Ctrl+V, etc.)
  if (event.ctrlKey || event.shiftKey || event.altKey || event.metaKey) {
    return
  }

  // Allow only digit and plus characters
  const allowedKeys = ['Tab', 'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', '+']
  if (!event.key.match(/\d/) && !allowedKeys.includes(event.key)) {
    event.preventDefault()
  }
}

function handlePhonePaste (event) {
  event.preventDefault()
  const text = (event.clipboardData || window.clipboardData).getData('text')
  // Filter the pasted text and combine it with the current input
  phoneNumber.value = text
    .replace(/[^\d+]/g, '')
    .replace(/(^\+)|(\+)/g, (match, p1) => p1 ? '+' : '')
}

//lifecycle
onMounted(() => {
  showSpinner()
  signinFlowApi
    .loadStep({ signinProcessStep: SigninProcessStep.ENABLE_2FA })
    .catch(() => router.push({ path: '/' }))
    .finally(() => hideSpinner())
})
</script>
