<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner-element :loading="isLoading"/>
      <auth-device-image class="ms-auth-device-image"/>
      <div class="ms-otp-form-title">{{ $t('auth.twoFactorAuthDevice.title') }}</div>
      <div class="ms-otp-caption">{{ $t('auth.twoFactorAuthDevice.description') }}</div>
      <input
        type="button"
        class="ms-otp-btn ms-otp-btn-lg"
        @click="pushToBackStep"
        :value="$t('default.back.title')"/>
      <div class="ms-link-navigation ms-link-navigation-custom">
        <a
          class="ms-forward-link"
          href="javascript:"
          @click='pushTo2FACodeStep'
          v-html="$t('auth.twoFactorAuthDevice.sendSmsInsteadText')"/>
      </div>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp"
        alt="login image">
    </div>
  </div>
</template>

<script setup>
import Stomp from 'webstomp-client'
import SpinnerElement from '@/components/helpers/SpinnerElement'
import { useSpinner } from '@/composables/useSpinner'
import { useRouter } from 'vue-router'
import { getCurrentInstance, onBeforeUnmount, onMounted, ref, unref } from 'vue'
import { SigninProcessService } from '@/service/SigninProcessService'
import { SigninProcessStep } from '@/constants/SigninProcessStep'
import { useI18n } from 'vue-i18n'
import { SigninProcessHeader } from '@/constants/SigninProcessHeader'
import { useToastNotification } from '@/composables/useToastNotification'
import AuthDeviceImage from '@/components/images/AuthDeviceImage'

//state
const { t } = useI18n()
const { appContext } = getCurrentInstance()
const signinFlowApi = appContext.config.globalProperties.$signinFlowApi
const router = useRouter()
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const { showErrorNotification } = useToastNotification()
const stompClient = ref(null)
const approvalRequestId = ref(null)
const websocketConnected = ref(false)
const reconnectAttempts = ref(0)
const reconnectTimeout = ref(5000)
const maxReconnectAttempts = ref(5)
const websocketSecurityError = ref(false)
const signinProcessService = new SigninProcessService(
  router,
  [
    SigninProcessStep.UPDATE_AGREEMENT,
    SigninProcessStep.UPDATE_PASSWORD,
    SigninProcessStep.LOGIN,
  ]
)

//methods
function pushTo2FACodeStep () {
  showSpinner()
  stompClient.value.disconnect()
  router.push({ path: '/signin/2fa/code' })
}

function send2FADevice () {
  showSpinner()
  signinFlowApi
    .send2FADevice()
    .then((response) => {
      approvalRequestId.value = response.data.approvalRequestId
      connectToWebsocket()
    })
    .catch(() => showWebsocketConnectionError())
    .finally(() => hideSpinner())
}

function showWebsocketConnectionError () {
  showErrorNotification(t('auth.twoFactorAuthDevice.connectionError'))
}

function pushToBackStep () {
  showSpinner()
  router.push({ path: '/' })
}

function enforceNextStep (data) {
  showSpinner()
  const params = {
    currentStep: SigninProcessStep.AUTH_2FA_DEVICE,
  }
  const headers = {
    [SigninProcessHeader.SIGNIN_PROCESS_TOKEN]: data.signinProcessToken,
  }
  signinFlowApi
    .enforceStep(params, headers)
    .then((response) => signinProcessService.pushToNextSigninStep(response.data))
    .catch(() => router.push({ path: '/' }))
}

function connectToWebsocket () {
  stompClient.value = Stomp.client(process.env.VUE_APP_AUTH_WEB_SOCKET_URL)
  stompClient.value.connect(
    {
      signinProcessStep: SigninProcessStep.AUTH_2FA_DEVICE
    },
    () => {
      websocketConnected.value = true
      reconnectAttempts.value = 0
      stompClient.value.subscribe(
        '/topic/2fa/device/' + approvalRequestId.value,
        (message) => {
          const data = JSON.parse(message.body)
          stompClient.value.disconnect()
          enforceNextStep(data)
        }
      )
    },
    (error) => {
      stompClient.value.disconnect()
      websocketConnected.value = false

      if (error?.command === 'ERROR' && error?.headers?.message?.includes('SubscribeToWebsocketException')) {
        websocketSecurityError.value = true
      }

      reconnectAttempts.value++
      if (!unref(websocketSecurityError) && unref(reconnectAttempts) < unref(maxReconnectAttempts)) {
        setTimeout(connectToWebsocket, unref(reconnectTimeout))
      } else {
        showWebsocketConnectionError()
      }
    },
  )
}

//lifecycle
onMounted(() => {
  showSpinner()
  signinFlowApi
    .loadStep({ signinProcessStep: SigninProcessStep.AUTH_2FA_DEVICE })
    .then(() => send2FADevice())
    .catch(() => router.push({ path: '/' }))
    .finally(() => hideSpinner())
})
onBeforeUnmount(() => {
  if (unref(stompClient) && unref(websocketConnected)) {
    stompClient.value.disconnect()
  }
})
</script>

<style lang="scss" scoped>
.ms-otp-content {
  max-width: unset !important;
  @include align-items(flex-start);

  @include mq('tablet', min) {
    padding: 96px 0 76px;
  }
  @include mq('desktop', min) {
    padding: 96px 0 76px;
  }

  .ms-auth-device-image {
    margin: 0 0 12px;
    @include mq('tablet', max) {
      width: 48px;
      height: 32px;
    }
  }

  .ms-otp-form-title {
    max-width: unset;
    margin-top: 0 !important;
  }

  .ms-otp-caption {
    max-width: 488px;
  }
}
</style>
