<template>
  <div id="app">
    <head-config/>
    <no-search-header v-if="isShowHeader"/>
    <main :class="[customMainClass ? customMainClass : '', 'ms-main']">
      <section :class="isEmbedUrl ? 'ms-app-wrap' : 'ms-register-wrap'">
        <div class="ms-container">
          <all-content/>
        </div>
      </section>
    </main>
    <footer-element v-if="isShowFooter"/>
  </div>
</template>

<script setup>
import NoSearchHeader from "@/components/header/NoSearchHeader";
import { useRoute, useRouter } from 'vue-router'
import { computed, unref, watch } from 'vue'
import AllContent from "@/components/AllContent";
import HeadConfig from "@/components/HeadConfig";
import FooterElement from "@/components/footer/FooterElement";
import { useI18n } from 'vue-i18n';

//state
const route = useRoute();
const router = useRouter();
const { locale } = useI18n();

const customMainClass = computed(() => route.meta.customMainClass);
const isEmbedUrl = computed(() => route.query && route.query.displayType === 'embed');
const isShowFooter = computed(() => !unref(isEmbedUrl) && !route.meta.hideFooter);
const isShowHeader = computed(() => !unref(isEmbedUrl));

//watchers
watch(locale, (newLocale, oldLocale) => {
  if (oldLocale !== newLocale) {
    router.changeLocale(newLocale)
  }
})
</script>


<style lang="scss">
* {
  box-sizing: border-box;
}
html {
  position: relative;
  width: 100%;
  height: 100%;
}
body {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0;
}
#app {
  width: 100%;
  height: 100%;
  min-height: 100%;
  @include flexbox;
  @include flex-direction(column);
  @include align-items(stretch);
}

.ms-main {
  flex-grow: 1;
  @include flexbox;
  width: 100%;
}

.ms-header-wrap, .ms-main, .ms-footer-wrap {
  flex-shrink: 0;
}

.ms-container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  @include mq('phablet', min) { // 768
    padding: 0 30px;
  }
  @include mq('tablet', min) { // 768
    padding: 0 30px;
  }
  @include mq('leptop', min) { // 1024
    padding: 0 20px;
  }
  @include mq('desktop', min) { // 1200
    max-width: 1180px;
    padding: 0;
  }
  @include mq('desktop-wide', min) { // 1440
    max-width: 1200px;
  }
}
.ms-register-wrap {
  width: 100%;
  margin: 70px 0 60px;
  @include mq('tablet', min) {
    margin: 75px 0 96px;
  }
}
.ms-app-wrap {
  width: 100%;
  margin: 70px 0 60px;
  @include mq('tablet', min) {
    margin: 62px 0 60px;
  }
}

//Toast notification styles
.v-toast {
  margin: 20px;
  &__bottom {
  }
  &__item {
    @include flexbox;
    @include align-items(center);
    position: relative;
    min-height: unset!important;
    padding: 18px;
    padding-right: 82px;
    margin: 0!important;
    @include border-radius(9px);
    @include mq('tablet', min) {}

    .v-toast__text {
      padding: 0!important;
      font-family: $origin-fonts-medium;
      font-size: 12px!important;
      @include mq('tablet', min) {
        font-size: 18px!important;
      }
    }
    &.v-toast__item--bottom-right {

      @include mq('tablet', max) {
        align-self: center;
        width: 100%;
      }
    }
    .v-toast__icon {
      margin: 0 16px 0 0!important;
      min-width: unset!important;
    }
    &.v-toast__item--success {
      background-color: #EFFFED;
      border: 2px solid #52AC7C;
      @include border-radius(9px);
      .v-toast__icon {
        background-image: url('~@/assets/images/toast/toast-success.svg')!important;
        background-repeat: no-repeat!important;
        background-size: 100% 100%!important;
        width: 16px;
        height: 16px;
        @include mq('tablet', min) {
          width: 18px;
          height: 18px;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 18px;
        width: 20px;
        border-bottom: 2px solid #52AC7C;
        @include transform(translate(0%, -50%) rotate(45deg));
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 18px;
        width: 20px;
        border-bottom: 2px solid #52AC7C;
        @include transform(translate(0%, -50%) rotate(-45deg));
      }
      .v-toast__text {
        color: #52AC7C;
      }
    }
    &.v-toast__item--error {
      background-color: #FEFAFA;
      border: 2px solid #D5645F;
      @include border-radius(9px);

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 18px;
        width: 20px;
        border-bottom: 2px solid #D5645F;
        @include transform(translate(0%, -50%) rotate(45deg));
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 18px;
        width: 20px;
        border-bottom: 2px solid #D5645F;
        @include transform(translate(0%, -50%) rotate(-45deg));
      }
      .v-toast__icon {
        background-image: url('~@/assets/images/toast/toast-error.svg')!important;
        background-repeat: no-repeat!important;
        background-size: 100% 100%!important;
        width: 16px;
        height: 16px;
        @include mq('tablet', min) {
          width: 18px;
          height: 18px;
        }
      }
      .v-toast__text {
        color: #D5645F;
      }
    }
  }
}
.v-toast__item--bottom-right {}
//Toast notification styles
</style>
