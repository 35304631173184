<template>
  <div class="ms-partner-content">
    <div class="ms-partner">
      <img
        src="../../../../../assets/images/logo-mns.png"
        class="ms-partner-logo">
      <div class="ms-partner-name">Swiss Medical Network</div>
    </div>
    <div class="ms-partner-heading">
      <h1 class="ms-partner-title">{{ $t('auth.oidcLogin.authPage.login.partner') }}SMN</h1>
      <div class="ms-partner-caption">{{ $t('auth.oidcLogin.appLink.smn') }}</div>
    </div>
    <oidc-page :authLink="authLink"/>
  </div>
</template>

<script setup>
import { OidcProvider } from '@/constants/OidcProvider'
import { useHead } from '@vueuse/head'
import OidcPage from '@/components/pages/signin/oidc/OidcPage'

//state
const authLink = OidcProvider.SMN.authLink

//meta
useHead({
  meta: [
    {
      name: 'referrer',
      content: 'no-referrer'
    }
  ]
})
</script>

<style lang="scss">
</style>
