import {defineStore} from 'pinia';

export const useStore = defineStore('app', {
    state: () => ({
        enable2FAPhoneNumber: '',
    }),
    actions: {
        setEnable2FAPhoneNumber(phoneNumber) {
            this.enable2FAPhoneNumber = phoneNumber;
        },
    },
    getters: {
        getEnable2FAPhoneNumber: () => this.enable2FAPhoneNumber,
    }
});
