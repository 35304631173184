import { getCurrentInstance } from 'vue'

export function useToastNotification () {
  const { appContext } = getCurrentInstance()
  const toast = appContext.config.globalProperties.$toast

  function showSuccessNotification (message) {
    toast.open({
      message: message,
      type: 'success',
      duration: 5000,
      position: 'top-right',
      dismissible: true,
      onClick: () => {
      },
      onDismiss: () => {
      },
      queue: false,
      pauseOnHover: true,
    })
  }

  function showErrorNotification (message) {
    toast.open({
      message: message,
      type: 'error',
      duration: 5000,
      position: 'top-right',
      dismissible: true,
      onClick: () => {
      },
      onDismiss: () => {
      },
      queue: false,
      pauseOnHover: true,
    })
  }

  return {
    showSuccessNotification,
    showErrorNotification,
  }
}

