<template>
  <div class="ms-otp-registration">
    <spinner-element :loading="isLoading"/>

    <div class="ms-otp-form-title">{{ $t('auth.acceptAgreement.title') }}</div>
    <div class="ms-otp-caption" v-html="$t('auth.acceptAgreement.subtitle')"/>
    <div class="ms-otp-registration-title-wrapper">
    </div>

    <div class="ms-otp-form-content-wrapper">
      <div
        class="ms-otp-register-agree-text"
        v-html="privacyPolicy"/>
      <span v-bind:class="[privacyFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
          <input
            type="checkbox"
            class="ms-otp-checkbox"
            id="privacy"
            name="privacy"
            v-model="privacy"
            @change="privacyFieldErrorMessage = privacy">
          <label
            class="ms-otp-check-label"
            for="privacy"
            v-html="$t('auth.patientRegistration.dataPrivacy.label', [localizeAgreementUrl('/content/legal')])"></label>
        </span>
      <span v-bind:class="[agbFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
          <input
            type="checkbox"
            class="ms-otp-checkbox"
            id="agb"
            name="agb"
            v-model="agb"
            @change="agbFieldErrorMessage = agb">
          <label
            class="ms-otp-check-label"
            for="agb"
            v-html="$t('auth.patientRegistration.agb.label', [localizeAgreementUrl('/content/userAgreement')])"></label>
        </span>
      <div class="ms-otp-btn-wrap">
        <button
          @click="declineUpdateAgreement"
          class="ms-otp-btn ms-otp-btn-lg ms-otp-btn-outline">
          {{ $t('auth.acceptAgreement.button.decline') }}
        </button>
        <button
          @click="submitUpdateAgreement"
          class="ms-otp-btn ms-otp-btn-lg">
          {{ $t('auth.acceptAgreement.button.accept') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSpinner } from '@/composables/useSpinner'
import SpinnerElement from '@/components/helpers/SpinnerElement'
import { useI18n } from 'vue-i18n'
import {useRoute, useRouter} from 'vue-router'
import { computed, getCurrentInstance, onMounted, ref, unref, watch } from 'vue'
import { useHead } from '@vueuse/head'
import { SigninProcessService } from '@/service/SigninProcessService'
import { SigninProcessStep } from '@/constants/SigninProcessStep'
import UrlManager from '@/util/UrlManager'
import { useValidationHandler } from '@/composables/useValidationHandler'
import {SigninProcessHeader} from "@/constants/SigninProcessHeader";

//state
const {
  t,
  locale
} = useI18n()
const route = useRoute()
const router = useRouter()
const { appContext } = getCurrentInstance()
const signinFlowApi = appContext.config.globalProperties.$signinFlowApi
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const {
  errors,
  getLocationError,
  getFieldError,
  setResponseErrors
} = useValidationHandler()
const email = ref('')
const agb = ref(false)
const privacy = ref(false)
const privacyPolicy = ref('')
const solutionHost = process.env.VUE_APP_SOLUTION_HOST
const signinProcessService = new SigninProcessService(
  router,
  [
    SigninProcessStep.LOGIN,
  ]
)

const signinProcessHeaders = computed(() => {
  const headers = {}
  if (route.query?.signinProcessToken) {
    headers[SigninProcessHeader.SIGNIN_PROCESS_TOKEN] = route.query.signinProcessToken
  }
  return headers
})
const privacyFieldErrorMessage = computed({
  get () {
    return getFieldError('privacy')
  },
  set () {
    const locationError = getLocationError('privacy')
    if (locationError) {
      errors.value = unref(errors).filter(el => el.location !== locationError.location)
    }
  }
})
const agbFieldErrorMessage = computed({
  get () {
    return getFieldError('agb')
  },
  set () {
    const locationError = getLocationError('agb')
    if (locationError) {
      errors.value = unref(errors).filter(el => el.location !== locationError.location)
    }
  }
})

//methods
function loadStep () {
  const params = {
    signinProcessStep: SigninProcessStep.UPDATE_AGREEMENT
  };
  return signinFlowApi
    .loadStep(params, unref(signinProcessHeaders))
    .then((response) => email.value = response.data.email)
    .catch(() => router.push({ path: '/' }))
}

function fetch () {
  return signinFlowApi
    .fetchPrivacyPolicy()
    .then((response) => privacyPolicy.value = response.data.object.item.localizedPrivacyPolicy)
}

function submitUpdateAgreement () {
  const params = {
    agb: unref(agb),
    privacy: unref(privacy),
  }

  showSpinner()
  signinFlowApi
    .updateAgreement(params, unref(signinProcessHeaders))
    .then((response) => signinProcessService.pushToNextSigninStep(response.data))
    .catch((error) => {
      setResponseErrors(error.response)
      hideSpinner()
    })
}

function declineUpdateAgreement () {
  router.push({ path: '/' })
}

function localizeAgreementUrl (path) {
  let currentUrl = new URL(window.location)

  if (currentUrl.searchParams.get('displayType') === 'embed') {
    path = '/embed' + path
  }
  return UrlManager.localizeUrl(solutionHost, path)
}

//watchers
watch(locale, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    showSpinner()
    fetch().then(() => hideSpinner())
  }
})

//lifecycle
onMounted(() => {
  showSpinner()

  Promise.all([
    loadStep(),
    fetch()
  ]).finally(() => hideSpinner())
})

//meta
useHead({
  title: t('auth.acceptAgreement.title')
})

</script>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/components/registration';

.ms-otp-form-title {
  text-align: start;
  @include mq('tablet', min) {
  }
  @include mq('desktop', min) {
    margin: 0 0 14px;
  }
}

.ms-otp-caption {
  @include mq('desktop', min) {
    margin: 0 0 30px;
  }
}

:deep(.ms-otp-register-agree-text) {
  max-height: 320px;
  padding: 29px 20px 36px 36px;
  margin: 0 auto 33px;
  font-family: $origin-fonts-medium;

  b {
    font-size: 18px;
    font-family: $origin-fonts-medium;
    font-weight: 500;
  }
}
</style>

