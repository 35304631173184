import {createApp} from 'vue';
import {createPinia} from 'pinia';
import {createHead} from '@vueuse/head';
import {i18n} from './i18n';
import axios from 'axios';
import App from './App.vue';
import * as Sentry from "@sentry/vue";
import 'select2/dist/css/select2.min.css';
import {RedirectParams} from "@/util/RedirectParams";
import {SigninFlowApi} from "@/api/SigninFlowApi";
import {AjaxLoader} from "@/util/ajaxLoader";
import Select2 from 'vue3-select2-component';
import {setupRouter} from "@/router";

export const redirectParams = new RedirectParams()

// Create Pinia store instance
const pinia = createPinia();

// Create Vue app instance
const app = createApp(App);
app.component('select-2', Select2)

// Initialize Head for managing head elements
const head = createHead();

// Create and configure the store
app.use(pinia);

const ajax = new AjaxLoader(axios);

// Configure global properties after initializing Pinia
app.config.globalProperties.$signinFlowApi = new SigninFlowApi(ajax);

// Use the rest of the plugins and mount the app
app.use(head);
app.use(i18n);

const router = setupRouter(app);

app.use(router);

const environment = process.env.VUE_APP_ENVIRONMENT
if (environment !== 'development') {
  Sentry.init({
    app,
    dsn: "https://d9d0c9cd08fb42c098f37b9f8060cac5@sentry.service.msio.cloud/43",
    environment: environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [process.env.VUE_APP_AUTH_HOST],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0 // [0.0-1.0] represents the percentage chance that any given transaction will be sent to Sentry.
  })
}

router.isReady().then(() => {
    app.mount('#app');
});
