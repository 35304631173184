<template>
  <div></div>
</template>

<script setup>
import {useHead} from '@vueuse/head';
import {useI18n} from 'vue-i18n';
import {unref} from "vue";
import UrlManager from "@/util/UrlManager";

//state
const {t, locale} = useI18n();

//methods
useHead({
  title: `${t('home.page.index.home-title')} - ${t('default.slogan')}`,
  link: [
    {
      rel: 'icon',
      type: 'image/x-icon',
      href: `${UrlManager.appendBaseUrl('favicon.ico')}`
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: `${UrlManager.appendBaseUrl('favicon-32x32.png')}`
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: `${UrlManager.appendBaseUrl('favicon-16x16.png')}`
    },
    {
      type: 'image/png',
      sizes: '192x192',
      href: `${UrlManager.appendBaseUrl('android-chrome-192x192.png')}`
    },
    {
      type: 'image/png',
      sizes: '256x256',
      href: `${UrlManager.appendBaseUrl('android-chrome-256x256.png')}`
    },
    {
      rel: 'mask-icon',
      color: '#5bbad5',
      href: `${UrlManager.appendBaseUrl('safari-pinned-tab.svg')}`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '57x57',
      type: 'image/png',
      href: `${UrlManager.appendBaseUrl('apple-touch-icon-57x57.png')}`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '60x60',
      type: 'image/png',
      href: `${UrlManager.appendBaseUrl('apple-touch-icon-60x60.png')}`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '72x72',
      type: 'image/png',
      href: `${UrlManager.appendBaseUrl('apple-touch-icon-72x72.png')}`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '76x76',
      type: 'image/png',
      href: `${UrlManager.appendBaseUrl('apple-touch-icon-76x76.png')}`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '114x114',
      type: 'image/png',
      href: `${UrlManager.appendBaseUrl('apple-touch-icon-114x114.png')}`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '120x120',
      type: 'image/png',
      href: `${UrlManager.appendBaseUrl('apple-touch-icon-120x120.png')}`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '144x144',
      type: 'image/png',
      href: `${UrlManager.appendBaseUrl('apple-touch-icon-144x144.png')}`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      type: 'image/png',
      href: `${UrlManager.appendBaseUrl('apple-touch-icon-152x152.png')}`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      type: 'image/png',
      href: `${UrlManager.appendBaseUrl('apple-touch-icon-180x180.png')}`,
    },
  ],
  meta: [
    {'http-equiv': 'Content-Type', content: 'text/html; charset=UTF-8'},
    {name: 'google-site-verification', content: '4cjO0q6u-rHlnGa87wvhcGGkfZZ1o4dbMjmPITWGtks'}
  ],
  htmlAttrs: {
    lang: unref(locale)
  },
  bodyAttrs: {
    class: 'lang-' + unref(locale)
  }
});
</script>
