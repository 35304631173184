<template>
  <div class="ms-error-page-404">
    <div class="ms-error-content">
      <div class="ms-error-status"><span>404</span> {{ $t('error.page.error.title') }}</div>
      <div
        class="ms-error-txt"
        v-html="$t('error.page.content_404')"/>
      <a
        :href="localizeUrl('/')"
        class="ms-error-btn"
        aria-label="Home link"
      >
        {{ $t('error.page.back.button') }}
      </a>
    </div>
    <div class="ms-error-img">
      <img src="@/assets/images/error-pages/error404.svg" alt="">
    </div>
  </div>
</template>

<script setup>
import UrlManager from '@/util/UrlManager'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'

//state
const { t } = useI18n()

//methods
function localizeUrl (path) {
  return UrlManager.localizeUrl('', path)
}

//meta
useHead({
  title: t('errorPage.404.pageTitle')
})

</script>

<style lang="scss">
@import "../../assets/stylesheets/components/error";
</style>
