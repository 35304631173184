<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner-element :loading="isLoading"/>
      <div class="ms-otp-form-title">{{ $t('auth.enableTwoFactorAuthCode.title') }}</div>
      <div
        class="ms-otp-caption"
        v-html="$t('auth.enableTwoFactorAuthCode.description', [phoneNumber])"></div>
      <form
        ref="formEl"
        @submit.prevent="submit2FACode"
        id="enable2FAForm"
        autocomplete="off">
        <div class="ms-otp-form-row">
          <div class="ms-otp-input-code-wrap">
            <otp-input
              ref="entryOtpInputEl"
              :channel="otpChannel"
              :input-classes="'ms-otp-input ms-otp-input-code' + (otpFieldErrorMessage ? ' ms-otp-error-field' : '')"
              @on-change="handleOtpChange"
              @on-complete="handleOtpComplete"
            />
          </div>
          <div class="ms-otp-error-field">
            <span
              v-if="otpFieldErrorMessage"
              class="ms-otp-error-text">{{ otpFieldErrorMessage }}</span>
          </div>
        </div>
        <div class="ms-otp-btn-wrap">
          <input
            type="button"
            class="ms-otp-btn ms-otp-btn-outline"
            @click="pushToBackStep"
            :value="$t('default.back.title')"/>
          <input
            type="submit"
            class="ms-otp-btn"
            id="submit"
            :value="$t('default.next.title')">
        </div>
        <div class="ms-link-navigation ms-link-navigation-custom">
          <a
            class="ms-forward-link"
            href="javascript:"
            @click="send2FACode"
            v-html="resendCodeText"/>
        </div>
      </form>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-otp-code.webp"
        alt="login image"
      >
    </div>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useSpinner } from '@/composables/useSpinner'
import { useStore } from '@/stores/useStore'
import { computed, getCurrentInstance, onMounted, ref, unref, watch } from 'vue'
import { OtpChannel } from '@/constants/OtpChannel'
import { SigninProcessService } from '@/service/SigninProcessService'
import { SigninProcessStep } from '@/constants/SigninProcessStep'
import SpinnerElement from '@/components/helpers/SpinnerElement'
import OtpUtils from '@/util/OtpUtils'
import OtpInput from '@/components/helpers/otp/OtpInput'
import { useValidationHandler } from '@/composables/useValidationHandler'

//state
const { appContext } = getCurrentInstance()
const signinFlowApi = appContext.config.globalProperties.$signinFlowApi
const { t } = useI18n()
const router = useRouter()
const store = useStore()
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const {
  errors,
  getLocationError,
  getFieldError,
  setResponseErrors
} = useValidationHandler()
const phoneNumber = ref('')
const code = ref('')
const secondsBeforeResend = ref(0)
const entryOtpInputEl = ref(null)
const otpChannel = OtpChannel.SMS
const signinProcessService = new SigninProcessService(
  router,
  [
    SigninProcessStep.ENABLE_2FA_SUCCESS,
  ]
)
const resendCodeText = computed(() => {
  return unref(secondsBeforeResend) > 0
    ? t('auth.otp.singInOtpPage.sendAgain.timer.title', [unref(secondsBeforeResend)])
    : t('auth.otp.singInOtpPage.sendAgain.ready.title')
})
const otpFieldErrorMessage = computed({
  get: () => getFieldError('otp'),
  set: () => {
    const locationError = getLocationError('otp')
    if (locationError) {
      errors.value = unref(errors).filter(el => el.location !== locationError.location)
    }
  }
})

//methods
function handleOtpChange (otp) {
  code.value = otp
  otpFieldErrorMessage.value = ''
}

function handleOtpComplete (otp) {
  handleOtpChange(otp)
  submit2FACode()
}

function send2FACode () {
  if (unref(secondsBeforeResend) > 0) {
    return
  }

  showSpinner()
  const params = {
    phoneNumber: unref(phoneNumber)
  }
  signinFlowApi
    .sendEnable2FACode(params)
    .then((response) => {
      secondsBeforeResend.value = OtpUtils.getResendLeftSeconds(response.data)
      unref(entryOtpInputEl).clearInput()
    })
    .catch((error) => setResponseErrors(error.response))
    .finally(() => hideSpinner())
}

function submit2FACode () {
  showSpinner()
  const params = {
    phoneNumber: unref(phoneNumber),
    otp: unref(code)
  }
  signinFlowApi
    .enable2FA(params)
    .then((response) => signinProcessService.pushToNextSigninStep(response.data))
    .catch((error) => {
      setResponseErrors(error.response)
      hideSpinner()
    })
}

function pushToBackStep () {
  showSpinner()
  router.push({ path: '/signin/2fa/enable/phone' })
}

//watchers
watch(secondsBeforeResend, (seconds) => {
  if (seconds > 0) {
    setTimeout(() => {
      secondsBeforeResend.value--
    }, 1000)
  }
})

//lifecycle
onMounted(() => {
  secondsBeforeResend.value = 60
  phoneNumber.value = store.$state.enable2FAPhoneNumber
  if (!unref(phoneNumber)) {
    pushToBackStep()
  }

  showSpinner()
  signinFlowApi
    .loadStep({ signinProcessStep: SigninProcessStep.ENABLE_2FA })
    .catch(() => router.push({ path: '/' }))
    .finally(() => hideSpinner())
})
</script>

<style lang="scss" scoped>
.ms-link-navigation {
  padding-top: 38px;
}
</style>
