import UrlManager from '@/util/UrlManager'

const REQUEST_SIGNIN_PATH = 'api/v2/signin/request'
const AUTHENTICATE_BY_OTP_PATH = 'api/v2/signin/auth/otp'
const AUTHENTICATE_BY_PASSWORD_PATH = 'api/v2/signin/auth/password'
const AUTHENTICATE_BY_2FA_CODE_PATH = 'api/v2/signin/auth/2fa/code'
const REGISTRATION_PATH = 'api/v2/signin/registration'
const UPDATE_AGREEMENT_PATH = 'api/v2/signin/update/agreement'
const UPDATE_PASSWORD_PATH = 'api/v2/signin/update/password'
const SEND_OTP_PATH = 'api/v2/signin/send/otp'
const SEND_2FA_CODE_PATH = 'api/v2/signin/send/2fa/code'
const SEND_2FA_DEVICE_PATH = 'api/v2/signin/send/2fa/device'
const LOAD_STEP_PATH = 'api/v2/signin/load/step'
const ENFORCE_STEP_PATH = 'api/v2/signin/enforce/step'
const SEND_ENABLE_2FA_CODE_PATH = 'api/v2/signin/send/enable/2fa/code'
const ENABLE_2FA_PATH = 'api/v2/signin/enable/2fa'
const CANCEL_2FA_PATH = 'api/v2/signin/cancel/2fa'
const ENABLE_2FA_SUCCESS_PATH = 'api/v2/signin/success/2fa'

const CAPTCHA_REQUIRED_PATH = 'api/v1/account/captchaRequired'

const FETCH_PRIVACY_POLICY_PATH = 'api/v2/content/privacy'

export class SigninFlowApi {
  constructor (ajaxLoader) {
    this.ajax = ajaxLoader
  }

  /**
   * @param params - email, withOidc, (captchaSubmitToken, captchaValidationToken) - if captcha required
   * @param headers
   * @returns {*} - nextSigninStep, signinProcessToken, (oidcProvider) - if next step is OIDC
   */
  requestSignin (params, headers = {}) {
    return this.ajax.post(REQUEST_SIGNIN_PATH, params, headers)
  }

  /**
   * @param params - otp
   * @param headers
   * @returns {*} - nextSigninStep, signinProcessToken, (accessToken, refreshToken) - if nextSigninStep is LOGIN
   */
  authenticateByOtp (params, headers = {}) {
    return this.ajax.post(
      AUTHENTICATE_BY_OTP_PATH, params, headers)
  }

  /**
   * @param params - password, (captchaSubmitToken, captchaValidationToken) - if captcha required
   * @param headers
   * @returns {*} - nextSigninStep, signinProcessToken, (accessToken, refreshToken) - if nextSigninStep is LOGIN
   */
  authenticateByPassword (params, headers = {}) {
    return this.ajax.post(AUTHENTICATE_BY_PASSWORD_PATH, params, headers)
  }

  /**
   * @param params - otp
   * @param headers
   * @returns {*} - nextSigninStep, signinProcessToken, (accessToken, refreshToken) - if nextSigninStep is LOGIN
   */
  authenticateBy2FACode (params, headers = {}) {
    return this.ajax.post(AUTHENTICATE_BY_2FA_CODE_PATH, params, headers)
  }

  /**
   * @param params - firstName, lastName, privacy, agb, loginOption, (password, confirmPassword) - if loginOption is PASSWORD
   * @param headers
   * @returns {*} - nextSigninStep, signinProcessToken, (accessToken, refreshToken) - if nextSigninStep is LOGIN
   */
  completeRegistration (params, headers = {}) {
    return this.ajax.post(REGISTRATION_PATH, params, headers)
  }

  /**
   * @param params - privacy, agb
   * @param headers
   * @returns {*} - nextSigninStep, signinProcessToken, (accessToken, refreshToken) - if nextSigninStep is LOGIN
   */
  updateAgreement (params, headers = {}) {
    return this.ajax.post(UPDATE_AGREEMENT_PATH, params, headers)
  }

  fetchPrivacyPolicy () {
    return this.ajax.get(
      UrlManager.localizeUrl(process.env.VUE_APP_SOLUTION_HOST, `/${FETCH_PRIVACY_POLICY_PATH}`),
      {},
      {
        'X-Api-Locale': '',
        'X-Service-Name': '',
        'X-Api-Key': process.env.VUE_APP_API_KEY
      }
    )
  }

  /**
   * @param params - password, confirmPassword
   * @param headers
   * @returns {*} - nextSigninStep, signinProcessToken, (accessToken, refreshToken) - if nextSigninStep is LOGIN
   */
  updatePassword (params, headers = {}) {
    return this.ajax.post(UPDATE_PASSWORD_PATH, params, headers)
  }

  /**
   * @param headers
   * @returns {*} - retryAfter, now, successfullySent
   */
  sendOtp (headers = {}) {
    return this.ajax.post(SEND_OTP_PATH, {}, headers)
  }

  /**
   * @param headers
   * @returns {*} - retryAfter, now, successfullySent
   */
  send2FACode (headers = {}) {
    return this.ajax.post(SEND_2FA_CODE_PATH, {}, headers)
  }

  /**
   * @param headers
   * @returns {*} - approvalRequestId
   */
  send2FADevice (headers = {}) {
    return this.ajax.post(SEND_2FA_DEVICE_PATH, {}, headers)
  }

  /**
   * @param params - signinProcessStep
   * @param headers
   * @returns {*} - () - if signinProcessStep is REQUESST_SIGNIN,
   *                (email, firstName, lastName) - if signinProcessStep is REGISTRATION,
   *                (phoneNumber) - if signinProcessStep is AUTH_2FA_CODE,
   *                (email) - if signinProcessStep is other
   */
  loadStep (params, headers = {}) {
    return this.ajax.post(LOAD_STEP_PATH, params, headers)
  }

  /**
   * @param params - currentStep
   * @param headers
   * @returns {*} - nextSigninStep, signinProcessToken, (accessToken, refreshToken) - if nextSigninStep is LOGIN
   */
  enforceStep (params, headers = {}) {
    return this.ajax.post(ENFORCE_STEP_PATH, params, headers)
  }

  /**
   * @param params - phoneNumber
   * @param headers
   * @returns {*} - retryAfter, now, successfullySent
   */
  sendEnable2FACode (params, headers = {}) {
    return this.ajax.post(SEND_ENABLE_2FA_CODE_PATH, params, headers)
  }

  /**
   * @param params - phoneNumber, otp
   * @param headers
   * @returns {*} - nextSigninStep, signinProcessToken
   */
  enable2FA (params, headers = {}) {
    return this.ajax.post(ENABLE_2FA_PATH, params, headers)
  }

  /**
   * @param headers
   * @returns {*} - nextSigninStep, signinProcessToken, (accessToken, refreshToken) - if nextSigninStep is LOGIN
   */
  cancel2FA (headers = {}) {
    return this.ajax.post(CANCEL_2FA_PATH, {}, headers)
  }

  /**
   * @param headers
   * @returns {*} - nextSigninStep, signinProcessToken, accessToken, refreshToken
   */
  enable2FASuccess (headers = {}) {
    return this.ajax.post(ENABLE_2FA_SUCCESS_PATH, {}, headers)
  }

  captchaRequired (params, headers = {}) {
    return this.ajax.get(CAPTCHA_REQUIRED_PATH, params, headers)
  }
}
