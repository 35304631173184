import AllContent from '@/components/AllContent.vue'
import { redirectParams } from '@/main'
import NotFoundPage from '@/views/error/NotFoundPage'
import SigninRegistration from '@/components/pages/signin/SigninRegistration.vue'
import UpdateAgreement from '@/components/pages/signin/UpdateAgreement.vue'
import Cookies from '@/util/Cookies'
import UserDataUtils from '@/util/UserDataUtils'
import UpdatePassword from '@/components/pages/signin/UpdatePassword.vue'
import AuthOtp from '@/components/pages/signin/AuthOtp.vue'
import AuthPassword from '@/components/pages/signin/AuthPassword.vue'
import RequestSignin from '@/components/pages/signin/RequestSignin.vue'
import Auth2FADevice from '@/components/pages/signin/2fa/Auth2FADevice.vue'
import Auth2FACode from '@/components/pages/signin/2fa/Auth2FACode.vue'
import Enable2FAQR from '@/components/pages/signin/2fa/Enable2FAQR.vue'
import Enable2FACode from '@/components/pages/signin/2fa/Enable2FACode.vue'
import Enable2FAPhone from '@/components/pages/signin/2fa/Enable2FAPhone.vue'
import Enable2FASuccess from '@/components/pages/signin/2fa/Enable2FASuccess.vue'
import SmnAuth from '@/components/pages/signin/oidc/partner/SmnAuth.vue'
import AlpineMindsAuth from '@/components/pages/signin/oidc/partner/AlpineMindsAuth.vue'
import MedicosearchAuth from '@/components/pages/signin/oidc/partner/MedicosearchAuth.vue'
import OidcAuthError from '@/components/pages/signin/oidc/OidcAuthError.vue'
import OidcAuth from '@/components/pages/signin/oidc/OidcAuth.vue'
import { createRouter, createWebHistory, isNavigationFailure } from 'vue-router'

const _getLocale = (path) => {
  return path.replace(/^\/([^/]+).*/i, '$1')
}

export const setupRouter = (app) => {
  const i18n = app.config.globalProperties.$i18n

  let locale = _getLocale(window.location.pathname)

  if (i18n.availableLocales.indexOf(locale) === -1) {
    locale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
  }
  i18n.locale = locale

  const urls = [
    {
      path: 'oidc',
      component: OidcAuth
    },
    {
      path: 'oidc-login-error',
      component: OidcAuthError
    },
    {
      path: 'auth/ms',
      component: MedicosearchAuth,
      meta: {
        hideFooter: true,
        customHeaderClass: 'sso-header'
      }
    },
    {
      path: 'auth/smn',
      component: SmnAuth,
      meta: {
        hideFooter: true,
        customHeaderClass: 'sso-header-smn'
      }
    },
    {
      path: 'auth/alpineminds',
      component: AlpineMindsAuth,
      meta: {
        hideFooter: true,
        customHeaderClass: 'sso-header'
      }
    },
    // signin flow
    {
      path: 'signin/otp',
      component: AuthOtp
    },
    {
      path: 'signin/password',
      component: AuthPassword
    },
    {
      path: 'signin/2fa/code',
      component: Auth2FACode
    },
    {
      path: 'signin/2fa/device',
      component: Auth2FADevice
    },
    {
      path: 'signin/registration',
      component: SigninRegistration
    },
    {
      path: 'signin/agreement/update',
      component: UpdateAgreement
    },
    {
      path: 'signin/password/update',
      component: UpdatePassword
    },
    {
      path: 'signin/2fa/enable/phone',
      component: Enable2FAPhone
    },
    {
      path: 'signin/2fa/enable/code',
      component: Enable2FACode
    },
    {
      path: 'signin/2fa/enable/success',
      component: Enable2FASuccess
    },
    {
      path: 'signin/2fa/enable/qr',
      component: Enable2FAQR
    }
  ]

  const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes: [
      {
        path: '/:locale(en|fr|it)?',
        component: RequestSignin
      },
      {
        path: '/:locale(en|fr|it)?',
        component: AllContent,
        children: urls,
      },
      {
        path: '/:locale(en|fr|it)?/404',
        name: '404',
        component: NotFoundPage,
        meta: {
          hideFooter: true,
          customMainClass: 'ms-error',
        },
      },
      {
        path: '/:locale(en|fr|it)?/:pathMatch(.*)*',
        component: NotFoundPage,
        meta: {
          hideFooter: true,
          customMainClass: 'ms-error',
        },
      },
    ]
  })

  const originalPush = router.push
  router.push = (location, onComplete, onAbort) => {
    if (onComplete || onAbort) {
      return originalPush.call(router, location, onComplete, onAbort)
    }

    return originalPush.call(router, location)
      .catch((err) => {
        if (isNavigationFailure(err)) {
          return err
        }
        return Promise.reject(err)
      })
  }

  router.beforeEach((to, from, next) => {
    let needToPush = false
    if (from.query && to.query) {
      if (from.query.displayType === 'embed' && to.query.displayType !== 'embed') {
        to.query.displayType = 'embed'
        needToPush = true
      }

      if (from.query.autofillType && to.query.autofillType !== from.query.autofillType) {
        to.query.autofillType = from.query.autofillType
        needToPush = true
      }
    }

    if (to.query) {
      if (to.query.apiKey) {
        UserDataUtils.setApiKey(to.query.apiKey)
        to.query.apiKey = undefined
        needToPush = true
      }

      if (to.query.displayType) {
        UserDataUtils.setDisplayType(to.query.displayType)
      } else {
        UserDataUtils.deleteDisplayType()
      }

      if (to.query.serviceName || to.query.backUrl) {
        redirectParams.setRedirectData(to.query.serviceName, to.query.backUrl)
        to.query.serviceName = undefined
        to.query.backUrl = undefined
        needToPush = true
      }
    }

    if (needToPush) {
      router.push(to)
      return
    }

    let urlLocale = _getLocale(to.path)

    if (i18n.availableLocales.indexOf(urlLocale) === -1) {
      urlLocale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
    }

    updatePreferredLanguage()

    if (i18n.locale !== urlLocale) {
      const newUrl = addLocalePrefixForUrl(to.path, i18n.locale, urlLocale)
      router.push({
        path: newUrl,
        query: to.query,
        params: to.params
      })
      return
    }

    if (isTrailingSlashForUrlNeeded(to.path)) {
      router.push({
        path: to.path + '/',
        query: to.query,
        params: to.params
      })
      return
    }
    next()
  })

  router.changeLocale = (newLocale) => {
    const url = window.location.pathname
    let currentUrlLocale =  _getLocale(url)

    if (i18n.availableLocales.indexOf(currentUrlLocale) === -1) {
      currentUrlLocale = process.env.VUE_APP_I18N_DEFAULT_LOCALE
    }

    if (newLocale !== currentUrlLocale) {
      const newUrl = addLocalePrefixForUrl(url, newLocale, currentUrlLocale)
      router.push({
        path: newUrl,
        query: router.currentRoute.value.query,
        params: router.currentRoute.value.params
      })
    }
  }

  function addLocalePrefixForUrl (url, newLocale, currentUrlLocale) {
    let newUrl = ''
    if (newLocale === process.env.VUE_APP_I18N_DEFAULT_LOCALE) {
      newUrl = url.replace(`/${currentUrlLocale}`, '')
    } else if (currentUrlLocale === process.env.VUE_APP_I18N_DEFAULT_LOCALE) {
      newUrl = `/${newLocale}${url}`
    } else {
      newUrl = url.replace(`/${currentUrlLocale}`, `/${newLocale}`)
    }
    if (isTrailingSlashForUrlNeeded(newUrl)) {
      newUrl += '/'
    }
    return newUrl
  }

  function isTrailingSlashForUrlNeeded (url) {
    const matchLocales = i18n.availableLocales.filter(locale => locale !== process.env.VUE_APP_I18N_DEFAULT_LOCALE).join('|')
    return url.match(`^/(${matchLocales})$`)
  }

  function updatePreferredLanguage () {
    const preferredLanguage = Cookies.getCookieValue('Preferred-Language')
    if (!preferredLanguage || preferredLanguage !== i18n.locale) {
      document.cookie = `Preferred-Language=${i18n.locale}; path=/; domain=${process.env.VUE_APP_SOLUTION_COOKIE_DOMAIN}`
    }
  }

  return router
}
