import {redirectParams} from "@/main";
import AllowedService from "@/constants/AllowedService";
import UserDataUtils from "@/util/UserDataUtils";
import {SigninProcessHeader} from "@/constants/SigninProcessHeader";
import {i18n} from "@/i18n";

export class AjaxLoader {
    constructor(axios) {
        axios.defaults.withCredentials = true
        this.axios = axios
        this.i18n = i18n
    }

    get(url, params = {}, headers = {}) {
        return this.handleResponse(
            this.axios.get(
                this.appendBaseUrl(url),
                {params: params, headers: this.buildHeaders(headers)}
            )
        )
    }

    post(url, params, headers = {}) {
        return this.handleResponse(
            this.axios.post(
                this.appendBaseUrl(url),
                params,
                {headers: this.buildHeaders(headers)}
            )
        )
    }

    buildHeaders(customHeaders) {
        const locale = this.i18n.global.locale.value;
        const serviceName = redirectParams.getServiceName()
        const apiKey = this.getApiKey()

        const headers = {}
        if (locale) {
            headers[SigninProcessHeader.API_LOCALE] = locale
        }
        if (serviceName) {
            headers[SigninProcessHeader.SERVICE_NAME] = serviceName
        }
        if (apiKey) {
            headers[SigninProcessHeader.API_KEY] = apiKey
        }
        const mergedHeaders = {...headers, ...customHeaders}

        return Object.fromEntries(Object.entries(mergedHeaders).filter(([, value]) => value))
    }

    handleResponse(resp) {
        return resp
            .then((response) => {
                return this.preHandlingSuccessfulResponse(response)
            })
            .catch((error) => {
                return this.preHandlingFailedResponse(error)
            })
    }

    preHandlingSuccessfulResponse(response) {
        return response
    }

    preHandlingFailedResponse(error) {
        throw error
    }

    appendBaseUrl(url) {
        if (url.match('^https?://')) {
            return url
        } else {
            return `${process.env.VUE_APP_AUTH_HOST}${url}`
        }
    }

    getApiKey() {
        if (AllowedService.MOBILE_APP.name === redirectParams.getServiceName()
            || AllowedService.WEB_APP.name === redirectParams.getServiceName()) {
            return UserDataUtils.getApiKey()
        } else {
            return null
        }
    }
}
