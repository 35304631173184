<template>
  <div class="ms-otp-input-wrap">
    <input
      ref="inputEl"
      :type="inputType"
      :inputmode="inputMode"
      :class="inputClasses"
      min="0"
      max="9"
      :maxlength="isLastChild ? 1 : 999"
      pattern="[0-9]"
      v-model="model"
      @input="handleOnInput"
      @keydown="handleOnKeyDown"
      @paste="handleOnPaste"
      @focus="handleOnFocus"
      @blur="handleOnBlur"
    />
    <span v-if="!isLastChild && separator">
      <span v-html="separator"></span>
    </span>
  </div>
</template>

<script setup>
// source code https://github.com/bachdgvn/vue-otp-input
import { onMounted, ref, unref, watch } from 'vue'

// keyCode constants
const BACKSPACE = 8
const LEFT_ARROW = 37
const RIGHT_ARROW = 39
const DELETE = 46

const props = defineProps({
  value: {
    type: String,
  },
  separator: {
    type: String,
  },
  focus: {
    type: Boolean,
  },
  inputClasses: {
    type: String,
  },
  shouldAutoFocus: {
    type: Boolean,
  },
  inputType: {
    type: String,
    default () {
      return 'tel'
    },
  },
  inputMode: {
    type: String,
    default () {
      return 'numeric'
    },
  },
  isLastChild: {
    type: Boolean,
  },
})

//state
const model = ref(props.value || '')
const inputEl = ref(null)

//emits
const emit = defineEmits(['on-keydown', 'on-paste', 'on-change', 'on-focus', 'on-blur'])

//methods
function handleOnInput (event) {
  const data = event.data
  if (!data) {
    event.preventDefault()
    return
  }

  if (data.length === 1) {
    emit('on-change', data)
  } else {
    emit('on-paste', data)
  }
}

function handleOnKeyDown (event) {
  const keyEvent = (event) || window.event
  const charCode = (keyEvent.which) ? keyEvent.which : keyEvent.keyCode
  if (isCodeNumeric(charCode)
    || [BACKSPACE, LEFT_ARROW, RIGHT_ARROW, DELETE].includes(charCode)
    || keyEvent.ctrlKey || keyEvent.metaKey) {
    emit('on-keydown', event)
  } else {
    event.preventDefault()
  }
}

function isCodeNumeric (charCode) {
  // numeric keys and numpad keys
  return (charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105)
}

function handleOnPaste (event) {
  event.preventDefault()
  const data = event.clipboardData.getData('text/plain')
  if (!data) {
    return
  }

  return emit('on-paste', data)
}

function handleOnFocus () {
  unref(inputEl).select()
  return emit('on-focus')
}

function handleOnBlur () {
  return emit('on-blur')
}

//watchers
watch(() => props.value, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    model.value = newValue
  }
})
watch(() => props.focus, (newFocusValue, oldFocusValue) => {
  if (oldFocusValue !== newFocusValue && unref(inputEl) && newFocusValue) {
    unref(inputEl)?.focus()
    unref(inputEl)?.select()
  }
})

//lifecycle
onMounted(() => {
  if (props.isLastChild) {
    unref(inputEl).maxLength = 1
  }
  if (unref(inputEl) && props.focus && props.shouldAutoFocus) {
    unref(inputEl)?.focus()
  }
})
</script>

<style scoped lang="scss">
.ms-otp-input-wrap {
  margin-right: 8px;

  &:nth-child(4) {
    margin-right: 20px;
  }
}
</style>
