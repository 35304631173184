<template>
  <div class="ms-otp-registration">
    <spinner-element :loading="isLoading"/>

    <div class="ms-otp-form-title">{{ $t('auth.patientRegistration.page.title') }}</div>
    <div class="ms-otp-caption">{{ $t('auth.patientRegistration.page.description') }}</div>

    <form
      @submit.prevent="submitRegistrationForm"
      id="registrationStepForm">
      <div class="ms-otp-form-content-wrapper">
        <div class="ms-otp-form-content-title">1. {{ $t('auth.patientRegistration.step1') }}</div>
        <div :class="[firstNameFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
          <input
            class="ms-otp-input"
            type="text"
            name="firstName"
            ref="firstNameInputEl"
            :placeholder="$t('auth.patientRegistration.placeholder.firstName')"
            v-model="firstName"
            @focus="firstNameFieldErrorMessage = null"/>
          <span
            v-if="firstNameFieldErrorMessage"
            class="ms-otp-error-text">{{ firstNameFieldErrorMessage }}</span>
        </div>
        <div v-bind:class="[lastNameFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
          <input
            class="ms-otp-input"
            type="text"
            name="lastName"
            :placeholder="$t('auth.patientRegistration.placeholder.lastName')"
            v-model="lastName"
            @focus="lastNameFieldErrorMessage = null">
          <span
            v-if="lastNameFieldErrorMessage"
            class="ms-otp-error-text">{{ lastNameFieldErrorMessage }}</span>
        </div>
        <div :class="[emailFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
          <input
            class="ms-otp-input"
            type="email"
            name="email"
            :placeholder="$t('auth.patientRegistration.placeholder.email')"
            v-model="email"
            @focus="emailFieldErrorMessage = null"
            disabled>
          <span
            v-if="emailFieldErrorMessage"
            class="ms-otp-error-text">{{ emailFieldErrorMessage }}</span>
        </div>
        <span class="ms-otp-checkbox-wrap ms-no-margin">
            <input
              type="checkbox"
              class="ms-otp-checkbox"
              id="show-password"
              v-model="checked"/>
            <label
              for="show-password"
              class="ms-otp-check-label">
              {{ $t('auth.patientRegistration.setPassword.title') }}
            </label>
            <span
              @click="toggleShowInformation"
              :class="{ active: showInformation }"
              class="ms-otp-tooltip-pw">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50"
                     fill="#32B1AB">
                <path
                  d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
                </svg>
                <span class="ms-tooltip-text">{{ $t('auth.patientRegistration.setPassword.tooltip') }}</span>
            </span>
          </span>
        <div
          v-if="checked"
          class="ms-set-password">
          <div v-bind:class="[passwordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <div class="ms-otp-input-password-wrap">
              <input
                v-if="showPassword"
                class="ms-otp-input ms-otp-input-password"
                type="text"
                name="password"
                :placeholder="$t('auth.patientRegistration.placeholder.password')"
                v-model="password"
                @focus="passwordFieldErrorMessage = null"
                autocomplete="new-password">
              <input
                v-else
                type="password"
                class="ms-otp-input ms-otp-input-password"
                :placeholder="$t('auth.login.password.placeholder')"
                v-model="password"
                @focus="passwordFieldErrorMessage = null"
                autocomplete="new-password">
              <div
                :class="{ 'ms-eye': showPassword, 'ms-eye-slash': !showPassword }"
                class="ms-show-password-btn"
                @click="togglePasswordShow">
                <icon-eye-slashed/>
                <icon-eye/>
              </div>
            </div>
            <span
              v-if="passwordFieldErrorMessage"
              class="ms-otp-error-text">{{ passwordFieldErrorMessage }}</span>
          </div>
          <div v-bind:class="[confirmPasswordFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-form-row']">
            <div class="ms-otp-input-password-wrap">
              <input
                v-if="showConfirmPassword"
                class="ms-otp-input ms-otp-input-password"
                type="text"
                name="password"
                :placeholder="$t('auth.patientRegistration.placeholder.confirmPassword')"
                v-model="confirmPassword"
                @focus="confirmPasswordFieldErrorMessage = null"
                autocomplete="new-password">
              <input
                v-else type="password"
                class="ms-otp-input ms-otp-input-password"
                :placeholder="$t('auth.login.confirmPassword.placeholder')"
                v-model="confirmPassword"
                @focus="confirmPasswordFieldErrorMessage = null"
                autocomplete="new-password">
              <div
                :class="{ 'ms-eye': showConfirmPassword, 'ms-eye-slash': !showConfirmPassword }"
                class="ms-show-password-btn"
                @click="toggleConfirmPasswordShow">
                <icon-eye-slashed/>
                <icon-eye/>
              </div>
            </div>
            <span
              v-if="confirmPasswordFieldErrorMessage"
              class="ms-otp-error-text">{{ confirmPasswordFieldErrorMessage }}</span>
          </div>
        </div>
        <div class="ms-otp-form-content-title ms-second-title">2. {{ $t('auth.patientRegistration.step2') }}</div>
        <span v-bind:class="[privacyFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
            <input
              type="checkbox"
              class="ms-otp-checkbox"
              id="privacy" name="privacy"
              v-model="privacy"
              @change="privacyFieldErrorMessage = privacy">
            <label
              class="ms-otp-check-label"
              for="privacy"
              v-html="$t('auth.patientRegistration.dataPrivacy.label', [localizeAgreementUrl('/content/legal')])"></label>
          </span>
        <div
          class="ms-otp-register-agree-text"
          v-html="privacyPolicy"></div>
        <span v-bind:class="[agbFieldErrorMessage ? 'ms-otp-error-field' : '', 'ms-otp-checkbox-wrap']">
            <input
              type="checkbox"
              class="ms-otp-checkbox"
              id="agb" name="agb"
              v-model="agb"
              @change="agbFieldErrorMessage = agb">
            <label
              class="ms-otp-check-label"
              for="agb"
              v-html="$t('auth.patientRegistration.agb.label', [localizeAgreementUrl('/content/userAgreement')])"></label>
        </span>
        <div class="ms-otp-btn-wrap">
          <router-link
            :to="{ path: localizeUrl('', '/') }"
            class="ms-otp-btn ms-otp-btn-outline">
            {{ $t('default.back.title') }}
          </router-link>
          <input
            type="submit"
            class="ms-otp-btn"
            :value="$t('default.register.title')"/>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import SpinnerElement from '@/components/helpers/SpinnerElement'
import IconEyeSlashed from '@/components/icons/IconEyeSlashed'
import IconEye from '@/components/icons/IconEye'
import { useHead } from '@vueuse/head'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed, getCurrentInstance, onMounted, ref, unref, watch } from 'vue'
import { useSpinner } from '@/composables/useSpinner'
import { SigninProcessHeader } from '@/constants/SigninProcessHeader'
import UrlManager from '@/util/UrlManager'
import { SigninProcessStep } from '@/constants/SigninProcessStep'
import { LoginOption } from '@/constants/LoginOption'
import { SigninProcessService } from '@/service/SigninProcessService'
import { useValidationHandler } from '@/composables/useValidationHandler'

//state
const route = useRoute()
const router = useRouter()
const { appContext } = getCurrentInstance()
const signinFlowApi = appContext.config.globalProperties.$signinFlowApi
const {
  t,
  locale
} = useI18n()
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const privacyPolicy = ref('')
const showPassword = ref(false)
const showConfirmPassword = ref(false)
const showInformation = ref(false)
const checked = ref(false)
const email = ref('')
const firstName = ref('')
const lastName = ref('')
const password = ref('')
const confirmPassword = ref('')
const privacy = ref(false)
const agb = ref(false)
const {
  errors,
  getLocationError,
  getFieldError,
  setResponseErrors
} = useValidationHandler()
const firstNameInputEl = ref(null)
const solutionHost = process.env.VUE_APP_SOLUTION_HOST
const signinProcessService = new SigninProcessService(
  router,
  [
    SigninProcessStep.LOGIN,
  ]
)

const signinProcessHeaders = computed(() => {
  const headers = {}
  if (route.query?.signinProcessToken) {
    headers[SigninProcessHeader.SIGNIN_PROCESS_TOKEN] = route.query.signinProcessToken
  }
  return headers
})
const firstNameFieldErrorMessage = computed({
  get () {
    return getFieldError('firstName')
  },
  set () {
    const locationError = getLocationError('firstName')
    if (locationError) {
      errors.value = errors.value.filter(el => el.location !== locationError.location)
    }
  }
})
const lastNameFieldErrorMessage = computed({
  get () {
    return getFieldError('lastName')
  },
  set () {
    const locationError = getLocationError('lastName')
    if (locationError) {
      errors.value = errors.value.filter(el => el.location !== locationError.location)
    }
  }
})
const emailFieldErrorMessage = computed({
  get () {
    return getFieldError('email')
  },
  set () {
    const locationError = getLocationError('email')
    if (locationError) {
      errors.value = errors.value.filter(el => el.location !== locationError.location)
    }
  }
})
const passwordFieldErrorMessage = computed({
  get () {
    return getFieldError('password')
  },
  set () {
    const locationError = getLocationError('password')
    if (locationError) {
      errors.value = errors.value.filter(el => el.location !== locationError.location)
    }
  }
})
const confirmPasswordFieldErrorMessage = computed({
  get () {
    return getFieldError('confirmPassword')
  },
  set () {
    const locationError = getLocationError('confirmPassword')
    if (locationError) {
      errors.value = errors.value.filter(el => el.location !== locationError.location)
    }
  }
})
const privacyFieldErrorMessage = computed({
  get () {
    return getFieldError('privacy')
  },
  set (val) {
    if (!val) return
    const locationError = getLocationError('privacy')
    if (locationError) {
      errors.value = errors.value.filter(el => el.location !== locationError.location)
    }
  }
})
const agbFieldErrorMessage = computed({
  get () {
    return getFieldError('agb')
  },
  set (val) {
    if (!val) return
    const locationError = getLocationError('agb')

    if (locationError) {
      errors.value = errors.value.filter(el => el.location !== locationError.location)
    }
  }
})

//methods
function fetchPrivacyPolicy () {
  return signinFlowApi
    .fetchPrivacyPolicy()
    .then((response) => privacyPolicy.value = response.data.object.item.localizedPrivacyPolicy)
}

function togglePasswordShow () {
  showPassword.value = !unref(showPassword)
}

function toggleConfirmPasswordShow () {
  showConfirmPassword.value = !unref(showConfirmPassword)
}

function toggleShowInformation () {
  showInformation.value = !unref(showInformation)
}

function localizeUrl (host, path) {
  return UrlManager.localizeUrl(host, path)
}

function localizeAgreementUrl (path) {
  let currentUrl = new URL(window.location)

  if (currentUrl.searchParams.get('displayType') === 'embed') {
    path = '/embed' + path
  }
  return UrlManager.localizeUrl(solutionHost, path)
}

function loadStep () {
  const params = {
    signinProcessStep: SigninProcessStep.REGISTRATION
  }
  return signinFlowApi
    .loadStep(params, unref(signinProcessHeaders))
    .then((response) => {
      email.value = response.data.email
      firstName.value = response.data.firstName
      lastName.value = response.data.lastName
    })
    .catch(() => router.push({ path: '/' }))
}

function submitRegistrationForm () {
  const loginOption = unref(checked) ? LoginOption.PASSWORD : LoginOption.OTP
  const params = {
    firstName: unref(firstName),
    lastName: unref(lastName),
    password: loginOption === LoginOption.PASSWORD ? unref(password) : null,
    confirmPassword: loginOption === LoginOption.PASSWORD ? unref(confirmPassword) : null,
    privacy: unref(privacy),
    agb: unref(agb),
    loginOption,
  }

  showSpinner()
  signinFlowApi
    .completeRegistration(params, unref(signinProcessHeaders))
    .then((response) => signinProcessService.pushToNextSigninStep(response.data))
    .catch((error) => {
      setResponseErrors(error.response)
      hideSpinner()
    })
}

//watchers
watch(locale, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    if (newValue !== oldValue) {
      showSpinner()
      fetchPrivacyPolicy()
        .then(() => hideSpinner())
    }
  }
})

//lifecycle
onMounted(() => {
  unref(firstNameInputEl)?.focus()
  showSpinner()
  Promise.all([
    loadStep(),
    fetchPrivacyPolicy()
  ]).finally(() => hideSpinner())
})

//meta
useHead({
  title: `${t('auth.patientRegistration.label.title')} - Medicosearch`
})
</script>

<style lang="scss" scoped>
@import '../../../assets/stylesheets/components/registration';

.ms-otp-form-title {
  @include mq('desktop', min) {
    margin: 0 0 6px;
  }
}

.ms-otp-caption {
  @include mq('desktop', min) {
    margin: 0 0 18px;
  }
}

:deep(.ms-otp-register-agree-text ) {
  max-height: 155px;
  padding: 17px 20px 36px 13px;
  margin: 0 0 26px;
}

.ms-set-password {
  padding: 29px 0 0;
}

.ms-otp-btn-wrap {
  padding: 0;
}
</style>
