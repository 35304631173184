import { ref, unref } from 'vue'

export function captchaHandler(signinFlowApi) {
  const captchaBoxId = 'msp-captcha-box'
  const captchaCodeBaseId = 'msp-captcha-code-base'

  const mosparoEl = ref(null)

  function checkAndCall(requestType, submitFunction) {
    return new Promise((resolve) => {
      const submitFunctionWrap = () => {
        Promise.resolve()
          .then(submitFunction)
          .then(resolve)
      }

      _removeCaptchaContent()
      return signinFlowApi.captchaRequired({ requestType })
        .then((response) => {
          const data = response.data
          if (data.captchaRequired) {
            return _renderCaptcha(data, submitFunctionWrap)
              .then(() => unref(mosparoEl)?.checkForm())
          } else {
            return submitFunctionWrap()
          }
        })
    })
  }

  function _renderCaptcha(data, submitFunctionWrap) {
    return new Promise((resolve) => {
      let recaptchaCodeBaseScript = document.getElementById(captchaCodeBaseId)
      if (recaptchaCodeBaseScript) {
        _initCaptcha(data, submitFunctionWrap, resolve)
      } else {
        const url = `${data.captchaHost}/build/mosparo-frontend.js`
        recaptchaCodeBaseScript = document.createElement('script')
        recaptchaCodeBaseScript.setAttribute('id', captchaCodeBaseId)
        recaptchaCodeBaseScript.setAttribute('src', url)
        recaptchaCodeBaseScript.onload = () => {
          _initCaptcha(data, submitFunctionWrap, resolve)
        }
        document.head.appendChild(recaptchaCodeBaseScript)
      }
    })
  }

  function _initCaptcha(data, submitFunctionWrap, resolve) {
    // eslint-disable-next-line no-undef
    mosparoEl.value = new mosparo(
      captchaBoxId,
      data.captchaHost,
      data.captchaIdentifier,
      data.captchaPublicKey,
      {
        loadCssResource: true,
        cssResourceUrl: `${data.captchaHost}/resources/${data.captchaIdentifier}.css`,
        onSwitchToInvisible: () => {
          resolve()
        },
        doSubmitFormInvisible: () => {
          submitFunctionWrap()
        },
      }
    )
  }

  function _removeCaptchaContent() {
    mosparoEl.value = null
    const newCaptchaContainer = document.createElement('div')
    newCaptchaContainer.id = captchaBoxId
    const captchaContainer = document.querySelector(`#${captchaBoxId}`)
    captchaContainer.replaceWith(newCaptchaContainer)
  }

  function getSubmitToken() {
    return _getToken('_mosparo_submitToken')
  }

   function getValidationToken() {
    return _getToken('_mosparo_validationToken')
  }

  function _getToken(tokenInputName) {
    const tokenNodes = document.getElementsByName(tokenInputName)
    if (tokenNodes.length === 0) {
      return null
    }
    return tokenNodes[0].value
  }

  return {
    captchaCheckAndCall: checkAndCall,
    getCaptchaSubmitToken: getSubmitToken,
    getCaptchaValidationToken: getValidationToken,
  }
}
