<template>
  <div class="ms-otp-content-wrap ms-otp-with-image">
    <div class="ms-otp-content">
      <spinner-element :loading="isLoading"/>
      <h1 class="ms-otp-form-title">{{ $t('auth.otp.singInLoginPasswordPage.title') }}</h1>
      <div class="ms-otp-caption" v-html="$t('auth.otp.singInLoginPasswordPage.description', [email])"></div>
      <form
        @submit.prevent="submitPasswordAuth"
        id="passwordStepForm">
        <div class="ms-otp-form-wrap">
          <div class="ms-otp-form-row">
            <div
              class="ms-otp-input-password-wrap"
              :class="{ 'ms-otp-error-field': passwordFieldErrorMessage }">
              <input
                :type="showPassword ? 'text' : 'password'"
                name="password"
                ref="passwordEl"
                class="ms-otp-input ms-otp-input-password mosparo__ignored-field"
                v-model="password"
                @keydown="onChangePasswordInput"
                :placeholder="$t('auth.otp.singInLoginPasswordPage.password.placeholder')"
                autocomplete="current-password"/>
              <div
                :class="{ 'ms-eye': showPassword, 'ms-eye-slash': !showPassword }"
                class="ms-show-password-btn"
                @click="toggleShow">
                <icon-eye-slashed/>
                <icon-eye/>
              </div>
            </div>
            <div class="ms-otp-error-field">
              <span
                v-if="passwordFieldErrorMessage"
                class="ms-otp-error-text">{{ passwordFieldErrorMessage }}</span>
            </div>
          </div>
          <!-- added for avoid mosparo captcha zero fields error on back validation -->
          <input
            ref="hiddenInputEl"
            type="text"
            value="hidden"
            autocomplete="off"
            hidden>
          <div id="msp-captcha-box"></div>
          <div
            v-if="captchaFieldErrorMessage"
            class="ms-otp-error-field">
            <span class="ms-otp-error-text">{{ captchaFieldErrorMessage }}</span>
          </div>
          <div class="ms-otp-btn-wrap">
            <router-link
              :to="{ path: localizeUrl('/') }"
              class="ms-otp-btn ms-otp-btn-outline">
              {{ $t('default.back.title') }}
            </router-link>
            <input
              type="submit"
              class="ms-otp-btn"
              id="submit"
              :disabled="submitLocked"
              :value="$t('default.login.title')"
            />
          </div>
        </div>
      </form>
      <div class="ms-link-navigation">
        <a
          class="ms-forward-link"
          href="javascript:"
          @click="redirectToOtp">
          {{ $t('auth.otp.singInLoginPasswordPage.sendActivationCode.title') }}
        </a>
      </div>
    </div>
    <div class="ms-otp-image-wrap">
      <img
        src="@/assets/images/pages/login-signin.webp"
        alt="login image">
    </div>
  </div>
</template>

<script setup>
import IconEyeSlashed from '@/components/icons/IconEyeSlashed'
import IconEye from '@/components/icons/IconEye'
import SpinnerElement from '@/components/helpers/SpinnerElement'
import { computed, getCurrentInstance, onMounted, ref, unref } from 'vue'
import { AuthenticationRequestType } from '@/constants/AuthenticationRequestType'
import { SigninProcessStep } from '@/constants/SigninProcessStep'
import { useRouter } from 'vue-router'
import { SigninProcessService } from '@/service/SigninProcessService'
import UrlManager from '@/util/UrlManager'
import { useValidationHandler } from '@/composables/useValidationHandler'
import { useSpinner } from '@/composables/useSpinner'
import { captchaHandler } from '@/util/CaptchaHandler'

//state
const { appContext } = getCurrentInstance()
const router = useRouter()
const {
  isLoading,
  showSpinner,
  hideSpinner
} = useSpinner()
const signinFlowApi = appContext.config.globalProperties.$signinFlowApi
const {
  errors,
  getLocationError,
  getFieldError,
  setResponseErrors
} = useValidationHandler()
const {
  captchaCheckAndCall,
  getCaptchaSubmitToken,
  getCaptchaValidationToken
} = captchaHandler(signinFlowApi)

const submitLocked = ref(false)

const showPassword = ref(false)
const email = ref('')
const password = ref(null)
const passwordEl = ref(null)
const hiddenInputEl = ref(null)
const signinProcessService = new SigninProcessService(
  router,
  [
    SigninProcessStep.AUTH_2FA_CODE,
    SigninProcessStep.AUTH_2FA_DEVICE,
    SigninProcessStep.UPDATE_AGREEMENT,
    SigninProcessStep.LOGIN,
  ]
)

const passwordFieldErrorMessage = computed({
  get () {
    return getFieldError('password')
  },
  set () {
    const locationError = getLocationError('password')
    if (locationError) {
      errors.value = unref(errors).filter(el => el.location !== locationError.location)
    }
  }
})
const captchaFieldErrorMessage = computed({
  get () {
    return getFieldError('captcha')
  },
  set () {
    const locationError = getLocationError('captcha')
    if (locationError) {
      errors.value = unref(errors).filter(el => el.location !== locationError.location)
    }
  }
})

//methods
function submitPasswordAuth() {
  if (submitLocked.value) {
    return
  }
  showSpinner()
  lockSubmit()
  // added for avoid safari autofill shadow content
  unref(hiddenInputEl).name = 'hidden'
  captchaCheckAndCall(AuthenticationRequestType.PASSWORD_LOGIN, submitForm)
    .catch((error) => {
      // added for avoid safari autofill shadow content
      unref(hiddenInputEl)?.removeAttribute('name')
      setResponseErrors(error.response)
      hideSpinner()
    })
    .finally(unlockSubmit)
}

async function submitForm() {
  const captchaSubmitToken = getCaptchaSubmitToken()
  const captchaValidationToken = getCaptchaValidationToken()
  const params = {
    password: unref(password),
    captchaSubmitToken,
    captchaValidationToken
  }
  signinFlowApi.authenticateByPassword(params)
    .then((response) => signinProcessService.pushToNextSigninStep(response.data))
    .catch((error) => {
      // added for avoid safari autofill shadow content
      unref(hiddenInputEl)?.removeAttribute('name')
      setResponseErrors(error.response)
      hideSpinner()
    })
}

function redirectToOtp() {
  router.push({ path: '/signin/otp' })
}

function localizeUrl(path) {
  return UrlManager.localizeUrl('', path)
}

function toggleShow() {
  showPassword.value = !unref(showPassword)
}

function onChangePasswordInput() {
  passwordFieldErrorMessage.value = ''
}

function lockSubmit() {
  submitLocked.value = true
}

function unlockSubmit() {
  submitLocked.value = false
}

//lifecycle
onMounted(() => {
  unref(passwordEl)?.focus()
  showSpinner()
  signinFlowApi
    .loadStep({ signinProcessStep: SigninProcessStep.AUTH_PASSWORD })
    .then((response) => {
      email.value = response.data.email
    })
    .catch(() => router.push({ path: '/' }))
    .finally(() => hideSpinner())
})
</script>
